import {
  Input as CInput,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
} from "@chakra-ui/react";
import { startCase } from "lodash";
import { RegisterOptions, useFormContext } from "react-hook-form";

type Props = InputProps & {
  name: string;
  label?: string | false;
  isRequired?: boolean;
  helperText?: string;
  validation?: RegisterOptions<Record<string, string>, string>;
};
export const Input = ({
  name,
  label,
  helperText,
  isRequired,
  validation,
  ...props
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Record<string, string>>();
  const error = errors[name];
  return (
    <FormControl isInvalid={!!error}>
      {label !== false && (
        <FormLabel htmlFor={name}>{`${label || startCase(name)}${
          isRequired ? "*" : ""
        }`}</FormLabel>
      )}
      <CInput
        id={name}
        {...props}
        {...register(name, {
          required: isRequired && "Required",
          ...validation,
        })}
      />
      {!error && helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};
