import { Categories } from "./Categories";
import { Global } from "./Global";
import { Itineraries } from "./Itineraries";
import { Listings } from "./Listings";
import { Pages } from "./Pages";
import { Regions } from "./Regions";
import { Stories } from "./Stories";
import {
  StrapiGetCategoriesParams,
  StrapiGetGlobalParams,
  StrapiGetItinerariesIdParams,
  StrapiGetListingsIdParams,
  StrapiGetPagesIdParams,
  StrapiGetRegionsIdParams,
  StrapiGetRegionsParams,
  StrapiGetStoriesIdParams,
  StrapiGetStoriesParams,
} from "./data-contracts";
import { toPopulateString } from "./helpers";
import { ApiConfig, RequestParams } from "./http-client";
import { PageData } from "./types";

const securityWorker = (): RequestParams => {
  return {
    format: "json",
    headers: {
      Authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
    },
  };
};

const baseParams: ApiConfig = {
  securityWorker,
  baseUrl: `${process.env.STRAPI_API_URL}/api`,
};

const strapiListingsApi = new Listings(baseParams);
const strapiRegionsApi = new Regions(baseParams);
const strapiPagesApi = new Pages(baseParams);
const strapiGlobalApi = new Global(baseParams);
const strapiStoriesApi = new Stories(baseParams);
const strapiItinerariesApi = new Itineraries(baseParams);
const strapiCategoriesApi = new Categories(baseParams);

export const getStrapiListing = (params: StrapiGetListingsIdParams) =>
  strapiListingsApi.getListingsId({ populate: "*", ...params }).then((res) => {
    const { attributes, id } = res?.data || {};
    if (!attributes) return null;
    return { ...attributes, id };
  });

export const getStrapiItinerary = (params: StrapiGetItinerariesIdParams) =>
  strapiItinerariesApi
    .getItinerariesId({
      populate: "*",
      ...params,
    })
    .then((res) => {
      const { attributes, id } = res?.data || {};
      if (!attributes) return null;
      return { ...attributes, id };
    });

export const getStrapiRegion = (params: StrapiGetRegionsIdParams) =>
  strapiRegionsApi
    .getRegionsId({
      populate: toPopulateString([{ taggedStories: "headerImage" }]),
      ...params,
    })
    .then((res) => {
      const { attributes, id } = res?.data || {};
      if (!attributes) return null;
      return { ...attributes, id };
    });

export const getStrapiRegions = (params?: StrapiGetRegionsParams) =>
  strapiRegionsApi
    .getRegions({ populate: "*", ...params })
    .then((res) => res.data ?? []);

export const getStrapiPage = async (
  params: StrapiGetPagesIdParams,
  customPageData?: PageData
): Promise<PageData> => {
  const pageData = await strapiPagesApi
    .getPagesId({ populate: "*", ...params })
    .then((res) => {
      const { attributes, id } = res?.data || {};
      if (!attributes) return {};
      return { ...attributes, id };
    });
  return { ...pageData, ...customPageData };
};

export const getStrapiGlobal = (params?: StrapiGetGlobalParams) =>
  strapiGlobalApi
    .getGlobal({
      populate: toPopulateString([
        { navigation: "socialMedia" },
        { socials: "links" },
        {
          home: {
            descriptionHome: "markdown",
            heroImage: "image",
            imageDetails: "text",
          },
        },
        {
          footer: {
            partnerImageLinks: "image",
            fundedImageLinks: "image",
          },
        },
        "landAcknowlegement",
      ]),
      ...params,
    })
    .then(({ data }) => data?.attributes!);

const STRAPI_STORY_POPULATE = toPopulateString([
  "headerImage",
  "title",
  {
    contentArea: [
      "image",
      "xLarge",
      "large",
      "medium",
      "small",
      "image1",
      "image2",
    ],
  },
  "seo",
  "taggedRegions",
]);

export const getStrapiStory = (params: StrapiGetStoriesIdParams) =>
  strapiStoriesApi
    .getStoriesId({ populate: STRAPI_STORY_POPULATE, ...params })
    .then((res) => res?.data ?? null);

export const getStrapiStories = (params?: StrapiGetStoriesParams) =>
  strapiStoriesApi.getStories({ populate: STRAPI_STORY_POPULATE, ...params });

export const getStrapiCategories = (params?: StrapiGetCategoriesParams) =>
  strapiCategoriesApi
    .getCategories({ populate: "*", ...params })
    .then((res) => res.data ?? []);

export * from "./data-contracts";
export * from "./types";
export * from "./helpers";
export * from "./components";
