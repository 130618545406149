import {
  Button as ChakraButton,
  ButtonProps,
  forwardRef,
} from "@chakra-ui/react";

export const Button = forwardRef<ButtonProps, "button">(({ ...props }, ref) => {
  const { children } = props;
  return (
    <ChakraButton {...props} ref={ref}>
      {children}
    </ChakraButton>
  );
});
