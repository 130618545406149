import * as React from "react";
import { SVGProps } from "react";
const Search = ({ ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#211E1E"}
      d="M23.016 19.395a12.187 12.187 0 1 0-2.62 2.621h-.001c.056.075.116.146.184.216l7.218 7.219a1.876 1.876 0 0 0 2.654-2.652l-7.22-7.218a1.87 1.87 0 0 0-.215-.186Zm.484-7.208a10.314 10.314 0 0 1-19.84 3.947 10.313 10.313 0 1 1 19.84-3.947Z"
    />
  </svg>
);
export default Search;
