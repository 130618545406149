import * as React from "react";
import { SVGProps } from "react";
const ArrowFullScreen = ({ ...props }: SVGProps<any>) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
      {...props}
    >
      <path
        fill={color || "#211E1E"}
        fillRule="evenodd"
        d="M10.928 19.073a.937.937 0 0 0-1.326 0l-7.68 7.68v-5.19a.938.938 0 0 0-1.875 0v7.453a.937.937 0 0 0 .937.937h7.454a.937.937 0 1 0 0-1.875h-5.19l7.68-7.68a.937.937 0 0 0 0-1.325Zm8.145 0a.937.937 0 0 1 1.325 0l7.68 7.68v-5.19a.938.938 0 0 1 1.875 0v7.453a.937.937 0 0 1-.937.937h-7.453a.937.937 0 1 1 0-1.875h5.19l-7.68-7.68a.937.937 0 0 1 0-1.325Zm0-8.146a.937.937 0 0 0 1.325 0l7.68-7.68v5.19a.938.938 0 0 0 1.875 0V.984a.937.937 0 0 0-.937-.937h-7.453a.938.938 0 0 0 0 1.875h5.19l-7.68 7.68a.937.937 0 0 0 0 1.325Zm-8.146 0a.937.937 0 0 1-1.325 0l-7.68-7.68v5.19a.938.938 0 0 1-1.875 0V.984A.938.938 0 0 1 .984.047h7.454a.937.937 0 1 1 0 1.875h-5.19l7.68 7.68a.937.937 0 0 1 0 1.325Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default ArrowFullScreen;
