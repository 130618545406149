import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ChevronDown = createIcon({
  displayName: "ChevronDown",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.086 8.711a.938.938 0 0 1 1.328 0L15 19.3 25.586 8.71a.94.94 0 0 1 1.328 1.328l-11.25 11.25a.938.938 0 0 1-1.328 0l-11.25-11.25a.938.938 0 0 1 0-1.328Z"
      clipRule="evenodd"
    />
  ),
});
