import { Link } from "@chakra-ui/next-js";
import {
  Card,
  CardBody,
  Divider,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { StrapiStoryListResponseDataItem } from "~/strapi";
type CardProps = {
  story: StrapiStoryListResponseDataItem;
};
export const SingleStoryCard = ({ story }: CardProps) => {
  return (
    <Link href={`/stories/${story.id}`}>
      <Card
        variant="outline"
        h={{ base: "507px" }}
        maxH="507px"
        sx={{ border: "2px solid", borderColor: "grey.900" }}
      >
        <Image
          objectFit="cover"
          maxW={{ base: "100%" }}
          h={{ base: "292px" }}
          alt="Story"
          borderRadius="5px 5px 0px 0px"
          src={story.attributes?.headerImage.data?.attributes?.url}
        />

        <CardBody p={{ base: "32px 16px" }}>
          <Heading fontSize="xl" fontWeight="regular" noOfLines={{ base: 2 }}>
            {story.attributes?.title}
          </Heading>
          <Divider m={{ base: "24px 0px" }} />
          <Text
            color="gray.500"
            fontSize="md"
            fontWeight="thin"
            noOfLines={{ base: 2 }}
          >
            {story.attributes?.shortDescription}
          </Text>
        </CardBody>
      </Card>
    </Link>
  );
};
