import { PropsWithChildren, createContext, useContext } from "react";
import { PageData } from "~/strapi";

const PageContext = createContext<PageData>({});

type Props = {
  value: PageData;
};

export const PageContextProvider = (props: PropsWithChildren<Props>) => {
  return <PageContext.Provider {...props} />;
};
export const usePageContext = () => useContext(PageContext);
