import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ChevronUp = createIcon({
  displayName: "ChevronUp",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.336 8.711a.938.938 0 0 1 1.328 0l11.25 11.25a.938.938 0 1 1-1.328 1.328L15 10.701 4.414 21.289a.939.939 0 0 1-1.328-1.328l11.25-11.25Z"
      clipRule="evenodd"
    />
  ),
});
