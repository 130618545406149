import { Box, Text, TextProps } from "@chakra-ui/react";
import { BlocksProps, StrapiBlocksQuoteComponent } from "~/strapi";

type Props = BlocksProps["quote_props"] & { block: StrapiBlocksQuoteComponent };

export const QuoteBlock = ({ block, wrapper_props, ...props }: Props) => {
  const { quote } = block;
  if (!quote) return null;
  return (
    <Box {...wrapper_props}>
      <Text
        textAlign="center"
        fontSize={{ base: "2xl" }}
        fontStyle="italic"
        {...props}
      >
        {quote}
      </Text>
    </Box>
  );
};
