import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  ModalBodyProps,
  useModalContext,
} from "@chakra-ui/react";
import { forwardRef, useEffect } from "react";
import { Subheading } from "~/components/Text";
import { ChevronLeft } from "~/images/svgs";
import { useIsDesktop, useNavbarHeight } from "~/lib/hooks";

type Props = DrawerProps & {
  bgColor?: DrawerContentProps["bgColor"];
  bgImage?: DrawerContentProps["bgImage"];
  bodyProps?: ModalBodyProps;
  showBack?: boolean;
};

export const MenuDrawer = forwardRef<HTMLDivElement, Props>(
  (
    { bgColor, bgImage, showBack = true, bodyProps, children, ...props },
    ref
  ) => {
    const navbarHeight = useNavbarHeight();
    const { isOpen } = props;
    useEffect(() => {
      document.documentElement.style.overflow = isOpen ? "hidden" : "auto";
    }, [isOpen]);
    return (
      <Drawer size="full" placement="top" {...props}>
        <DrawerOverlay />
        <DrawerContent
          transitionDuration={"100ms"}
          pt={navbarHeight}
          bgColor={bgColor}
          bgImage={bgImage}
          h="100%"
        >
          {showBack && <BackButton />}
          <DrawerBody {...bodyProps} ref={ref}>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }
);

MenuDrawer.displayName = "MenuDrawer";

const BackButton = () => {
  const isDesktop = useIsDesktop();
  const { onClose } = useModalContext();
  if (isDesktop) return null;
  return (
    <DrawerHeader transitionDuration={"800ms"}>
      <Flex alignItems="center" onClick={onClose}>
        <ChevronLeft color="gray.900" />
        <Subheading fontSize="sm" ml="8px" color="gray.900">
          Back to main menu
        </Subheading>
      </Flex>
    </DrawerHeader>
  );
};
