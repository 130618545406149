import { forwardRef } from "react";
import { BlocksProps, StrapiBlocksImageComponent } from "~/strapi";
import { Image } from "./Image";

type Props = BlocksProps["image_props"] & {
  block: StrapiBlocksImageComponent;
};

export const ImageBlock = forwardRef<HTMLImageElement, Props>(
  ({ block, ...image_props }, ref) => {
    const { image, caption } = block;
    return <Image ref={ref} image={image} caption={caption} {...image_props} />;
  }
);

ImageBlock.displayName = "ImageBlock";
