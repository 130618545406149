import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

export const Checkbox = defineStyleConfig({
  defaultProps: {
    size: "lg",
  },
  baseStyle: {
    control: {
      borderRadius: "4px",
      border: "1px solid",
      borderColor: "blue.300",
      h: "30px",
      w: "30px",
      _hover: {
        backgroundColor: "blue.50",
      },

      _checked: {
        // borderColor: "black",
      },
    },
  },
});
