import { Box, BoxProps } from "@chakra-ui/react";
import { useRef } from "react";
import { useResizeObserver } from "usehooks-ts";
/**
 * Makes the image repeat almost infinitely.
 * Sets multiple bg images and offsets them so they slightly overlap so the text appears to continue.
 */
export const TheWindingRoute = (props: BoxProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { height = 0 } = useResizeObserver({ ref, box: "border-box" });
  // 780 is the real height of the image, 2316 is the real width of the image minus the amount we want to overlap
  const w = height * (2316 / 780);
  const bg = [...Array(10)]
    .map((_, i) => `url('/CurvedLineWithShield.png') ${w * i}px 100%`)
    .join(", ");
  return (
    <Box ref={ref} bg={bg} bgSize="auto 100%" bgRepeat="no-repeat" {...props} />
  );
};
