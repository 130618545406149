import { Box, BoxProps, Text } from "@chakra-ui/react";
import { Children, PropsWithChildren } from "react";
type PhotoAttributeProps = {
  text: string;
};
export const PhotoAttribute = ({ children }: PropsWithChildren<BoxProps>) => {
  if (!Children.count(children)) return null;
  return (
    <Box
      w="max-content"
      m={{ base: "32px" }}
      bgColor="rgba(51, 51, 51, 0.6)"
      padding="12px 16px"
      borderRadius={10}
    >
      <Text fontStyle="italic" fontWeight="thin" color="white">
        {children}
      </Text>
    </Box>
  );
};
