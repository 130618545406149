import { theme } from "@chakra-ui/react";
import * as React from "react";
import { SVGProps } from "react";
const FilterLeftSmall = ({ ...props }: SVGProps<any>) => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8.625C0.5 8.45924 0.565848 8.30027 0.683058 8.18306C0.800269 8.06585 0.95924 8 1.125 8H4.875C5.04076 8 5.19973 8.06585 5.31694 8.18306C5.43415 8.30027 5.5 8.45924 5.5 8.625C5.5 8.79076 5.43415 8.94973 5.31694 9.06694C5.19973 9.18415 5.04076 9.25 4.875 9.25H1.125C0.95924 9.25 0.800269 9.18415 0.683058 9.06694C0.565848 8.94973 0.5 8.79076 0.5 8.625ZM0.5 4.875C0.5 4.70924 0.565848 4.55027 0.683058 4.43306C0.800269 4.31585 0.95924 4.25 1.125 4.25H9.875C10.0408 4.25 10.1997 4.31585 10.3169 4.43306C10.4342 4.55027 10.5 4.70924 10.5 4.875C10.5 5.04076 10.4342 5.19973 10.3169 5.31694C10.1997 5.43415 10.0408 5.5 9.875 5.5H1.125C0.95924 5.5 0.800269 5.43415 0.683058 5.31694C0.565848 5.19973 0.5 5.04076 0.5 4.875ZM0.5 1.125C0.5 0.95924 0.565848 0.800269 0.683058 0.683058C0.800269 0.565848 0.95924 0.5 1.125 0.5H14.875C15.0408 0.5 15.1997 0.565848 15.3169 0.683058C15.4342 0.800269 15.5 0.95924 15.5 1.125C15.5 1.29076 15.4342 1.44973 15.3169 1.56694C15.1997 1.68415 15.0408 1.75 14.875 1.75H1.125C0.95924 1.75 0.800269 1.68415 0.683058 1.56694C0.565848 1.44973 0.5 1.29076 0.5 1.125Z"
        fill={theme.colors.gray[500]}
      />
    </svg>
  );
};
export default FilterLeftSmall;
