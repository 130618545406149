import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ArrowLeft = createIcon({
  displayName: "ArrowLeft",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.5 15a.938.938 0 0 1-.938.938H10.701l4.025 4.024a.938.938 0 1 1-1.327 1.327l-5.625-5.625a.938.938 0 0 1 0-1.328l5.625-5.625a.939.939 0 0 1 1.327 1.328l-4.025 4.024h10.861A.938.938 0 0 1 22.5 15Z"
      clipRule="evenodd"
    />
  ),
});
