import React from "react";

export const Crow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="121"
      fill="none"
      viewBox="0 0 156 121"
    >
      <g clipPath="url(#clip0_903_5)">
        <path
          fill="#211E1E"
          d="M152.76 29.42l-.18-.26a26.998 26.998 0 00-16-10.32l-15-3.39L82 2.35a34.77 34.77 0 00-33.94 7l-8.86 7.81a95.001 95.001 0 01-23.82 15.43l-7.85 3.5s-20.09 24 5.37 53.26a76.313 76.313 0 0012.65 11.5l26.61 19.46c9.67-8.64 28.66-29.18 39.5-64.77.7-2.31 2.61-4.18 3.9-6.22a27.341 27.341 0 0123-13.13h28.67a9.395 9.395 0 017.38 3.59l1.17 1.5v-1.95a17.16 17.16 0 00-3.02-9.91zm-69.18 12.4a.22.22 0 000 .08 27.82 27.82 0 01-39.62 7.53l-8.73-6.3a57.49 57.49 0 0126.51-6.54 51.17 51.17 0 0121.14 4.4l1 .45c-.11.15-.22.25-.3.38zm2.06-3.71c-1.19-.55-12-6.92-24-8.18-10.28-1.08-13.33.88-19.7 2.92 2.24-1.68 4.44-3.43 6.55-5.29l8.82-7.8a20.759 20.759 0 018.54-4.53 20.395 20.395 0 015.15-.64c.54 0 1.07 0 1.6.06.37 0 .74.07 1.1.12.36.05.74.11 1.1.17l12.72 4 4.12 1.36 13.74 4.55a46.998 46.998 0 00-19.74 13.26z"
        ></path>
        <path
          fill="#FFDD4E"
          d="M80.05 28.3a7.7 7.7 0 007.71-7.71 7.606 7.606 0 00-.19-1.66l-12.72-4a7.69 7.69 0 005.2 13.37z"
        ></path>
        <path
          fill="#009A5B"
          d="M61.74 36.59a57.49 57.49 0 00-26.56 6.54l8.73 6.3a27.822 27.822 0 0039.62-7.53.22.22 0 010-.08c.08-.13.19-.25.28-.38l-1-.45a51.168 51.168 0 00-21.07-4.4z"
        ></path>
        <path
          fill="#3893D1"
          d="M87.57 18.94a7.61 7.61 0 01.19 1.65 7.71 7.71 0 11-12.91-5.67c-.36-.06-.73-.13-1.1-.17-.37-.04-.73-.09-1.1-.12-.55-.04-1.08-.04-1.65-.04a20.4 20.4 0 00-5.16.66 20.759 20.759 0 00-8.54 4.53l-8.79 7.81c-2.11 1.83-4.31 3.58-6.51 5.26 6.37-2 9.42-4 19.7-2.92 12 1.26 22.79 7.63 24 8.18a47 47 0 0119.79-13.26l-13.8-4.55-4.12-1.36z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_903_5">
          <path
            fill="#fff"
            d="M0 0H155.83V119.72H0z"
            transform="translate(0 .59)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
