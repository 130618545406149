import { createContext, useContext, PropsWithChildren } from "react";
import { StrapiGlobal } from "~/strapi";

const DEFAULT_VALUE: StrapiGlobal = {
  navigation: {},
  footer: {},
};

const GlobalContext = createContext<NonNullable<StrapiGlobal>>(DEFAULT_VALUE);

type Props = {
  value: StrapiGlobal;
};

export const GlobalContextProvider = ({
  value = DEFAULT_VALUE,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
export const useGlobalContext = () => useContext(GlobalContext);
