import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const Twitter = createIcon({
  displayName: "Twitter",
  viewBox: "0 0 20 20",
  path: (
    <path
      fill="currentColor"
      d="M6.282 18.75c7.548 0 11.677-6.254 11.677-11.668 0-.175 0-.352-.008-.527A8.357 8.357 0 0 0 20 4.427c-.75.331-1.547.55-2.361.648a4.126 4.126 0 0 0 1.809-2.271 8.166 8.166 0 0 1-2.61.99 4.107 4.107 0 0 0-6.994 3.743 11.656 11.656 0 0 1-8.459-4.286A4.111 4.111 0 0 0 2.658 8.73 4.154 4.154 0 0 1 .8 8.219v.056a4.11 4.11 0 0 0 3.29 4.022 4.002 4.002 0 0 1-1.081.144c-.258 0-.515-.023-.768-.071a4.104 4.104 0 0 0 3.834 2.846 8.235 8.235 0 0 1-5.1 1.759A7.884 7.884 0 0 1 0 16.919a11.68 11.68 0 0 0 6.282 1.83Z"
    />
  ),
});
