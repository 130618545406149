import { DrawerProps, Flex, SimpleGrid } from "@chakra-ui/react";
import { Subheading } from "~/components/Text";
import {
  ItineratorItineraryList,
  ItineratorSimpleItinerary,
  itineraryApi,
} from "@itinerator/next-package";
import { Link } from "@chakra-ui/next-js";
import { MenuDrawer } from "./MenuDrawer";
import { useEffect, useState } from "react";
import { useIsMobile } from "~/lib/hooks";
import { Polaroid } from "~/components/Polaroid";

type Props = Omit<DrawerProps, "children">;

export const RoadTripsDrawer = ({ ...props }: Props) => {
  const [itineraries, setItineraries] = useState<ItineratorItineraryList>([]);
  const [image, setImage] = useState("/CommunitiesImage.jpg");
  const isMobile = useIsMobile();

  useEffect(() => {
    itineraryApi
      .getItineraries()
      .then((itins) => itins)
      .then(setItineraries);
  }, []);
  const handlehover = (itin: ItineratorSimpleItinerary) => {
    setImage(itin.lead_image?.url || "/CommunitiesImage.jpg");
  };
  return (
    <MenuDrawer
      placement={isMobile ? "right" : "top"}
      {...props}
      bgColor="blue.300"
    >
      <Subheading
        fontSize="4xl"
        borderBottom="2px solid"
        mb={{ base: 6, lg: 12 }}
        pb={6}
      >
        Road Trips
      </Subheading>
      <Flex
        flexDir={{ base: "column", lg: "row-reverse" }}
        alignItems={{ base: "flex-start", lg: "center" }}
        justifyContent={"space-around"}
      >
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacingX={10}
          rowGap={{ base: 5, lg: 12 }}
          mb={6}
        >
          {itineraries.map((itin) => {
            return (
              <Link
                key={itin.id}
                href={`/roadtrips/${itin.slug}`}
                color="gray.900"
                fontWeight="bold"
                fontSize={{ base: "xl", lg: "3xl" }}
                onMouseOver={() => handlehover(itin)}
              >
                {itin.name}
              </Link>
            );
          })}
        </SimpleGrid>
        <Polaroid
          image={image}
          w={{ base: "142px", lg: "371px" }}
          h={{ base: "164px", lg: "426px" }}
          transform="rotate(-3deg)"
          p={{ base: 2, md: 4 }}
          mb={{ base: 6 }}
        />
      </Flex>
    </MenuDrawer>
  );
};
