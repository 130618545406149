import { Box } from "@chakra-ui/react";
import { MarkdownComponentProps } from "~/strapi";
import { ReactMarkdown } from "~/components";

export const Markdown = ({
  wrapper_props,
  ...props
}: MarkdownComponentProps) => {
  return (
    <Box {...wrapper_props}>
      <ReactMarkdown {...props} />
    </Box>
  );
};
