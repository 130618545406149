import { ItineratorStop } from "@itinerator/next-package";
import { Box, Heading, Text } from "@chakra-ui/react";
import { Dispatch, RefObject, SetStateAction } from "react";
import { ArrowLeft, PinSolid } from "~/images/svgs";
import { ReactMarkdown } from "../Text";
import { MapStopCard } from "./MapStopCard";

type OpenBoxStopsProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  boxRef: RefObject<HTMLDivElement>;
  title: string;
  description?: string;
  stops: ItineratorStop[];
};

export const OpenBoxStops = ({
  setOpen,
  open,
  boxRef,
  title,
  description,
  stops,
}: OpenBoxStopsProps) => {
  return (
    <Box
      bgColor="white"
      onClick={() => setOpen(!open)}
      ref={boxRef}
      padding={{ base: "32px" }}
      borderRadius="10px"
      w={{ base: "100%", md: "452px" }}
      sx={{ _hover: { boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)" } }}
    >
      <Box display="flex" justifyContent="flex-start" gap="8px">
        <ArrowLeft boxSize="20px" />
        <Heading
          ml={{ base: "12px" }}
          fontSize={{ base: "xl" }}
          fontWeight={{ base: 400 }}
          mb="8px"
        >
          {title}
        </Heading>
      </Box>

      <Box
        display="flex"
        mb="16px"
        gap="4px"
        alignItems="center"
        ml={{ base: "36px" }}
      >
        <PinSolid boxSize="11px" mt="2px" />
        <Text fontSize={{ base: "md" }}>
          {stops.length} {stops.length === 1 ? "stop" : "stops"}
        </Text>
      </Box>

      {description && (
        <Box marginLeft={{ base: "32px" }}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </Box>
      )}
      {stops.map((stop) => {
        return <MapStopCard stop={stop} key={stop.id} />;
      })}
    </Box>
  );
};
