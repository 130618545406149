import { Box, Button } from "@chakra-ui/react";
import { StrapiBlocksButtonComponent } from "../data-contracts";
import { BlocksProps } from "../types";
import { Link } from "@chakra-ui/next-js";

type Props = BlocksProps["button_props"] & {
  block: StrapiBlocksButtonComponent;
};
export const ButtonBlock = ({
  block,
  wrapper_props,
  ...button_props
}: Props) => {
  return (
    <Box
      w="100%"
      textAlign={block.align !== "full" ? block.align : undefined}
      {...wrapper_props}
    >
      <Button
        width={block.align === "full" ? "100%" : undefined}
        as={Link}
        href={block.href || ""}
        shallow={block.shallow}
        replace={block.replace}
        {...button_props}
      >
        {block.label}
      </Button>
    </Box>
  );
};
