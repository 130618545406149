import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ChevronRight = createIcon({
  displayName: "ChevronRight ",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.71122 3.08622C8.7983 2.99891 8.90176 2.92964 9.01565 2.88238C9.12955 2.83512 9.25165 2.81079 9.37497 2.81079C9.49828 2.81079 9.62038 2.83512 9.73428 2.88238C9.84818 2.92964 9.95163 2.99891 10.0387 3.08622L21.2887 14.3362C21.376 14.4233 21.4453 14.5268 21.4926 14.6407C21.5398 14.7546 21.5641 14.8767 21.5641 15C21.5641 15.1233 21.5398 15.2454 21.4926 15.3593C21.4453 15.4732 21.376 15.5766 21.2887 15.6637L10.0387 26.9137C9.86268 27.0898 9.62392 27.1887 9.37497 27.1887C9.12601 27.1887 8.88725 27.0898 8.71122 26.9137C8.53518 26.7377 8.43628 26.4989 8.43628 26.25C8.43628 26.001 8.53518 25.7623 8.71122 25.5862L19.2993 15L8.71122 4.41372C8.62391 4.32663 8.55464 4.22318 8.50738 4.10928C8.46012 3.99538 8.43579 3.87328 8.43579 3.74997C8.43579 3.62665 8.46012 3.50455 8.50738 3.39065C8.55464 3.27676 8.62391 3.1733 8.71122 3.08622Z"
    />
  ),
});
