import { Link, LinkProps } from "@chakra-ui/next-js";

export type Href = LinkProps["href"];
type Props = LinkProps & {
  href: Href;
};

export const NavLink = ({ href, ...rest }: Props) => {
  return (
    <Link
      fontFamily="body"
      color="gray.900"
      fontSize="xl"
      href={href}
      {...rest}
    />
  );
};

export const LargeLink = ({ href, ...rest }: Props) => {
  return (
    <Link
      color="gray.900"
      fontFamily="heading"
      fontSize="4xl"
      fontWeight="bold"
      href={href}
      {...rest}
    />
  );
};

export const EventLink = ({ href, ...rest }: Props) => {
  return (
    <Link
      color="gray.900"
      fontFamily="heading"
      fontSize={{ base: "xl", md: "3xl" }}
      href={href}
      {...rest}
    />
  );
};

export const RoadTripLink = ({ href, ...rest }: Props) => {
  return <Link color="gray.900" fontSize="xl" href={href} {...rest} />;
};
