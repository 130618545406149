/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { StrapiError, StrapiGetGlobalParams, StrapiGlobalRequest, StrapiGlobalResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Global<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Global
   * @name GetGlobal
   * @request GET:/global
   * @secure
   */
  getGlobal = (query: StrapiGetGlobalParams, params: RequestParams = {}) =>
    this.request<StrapiGlobalResponse, StrapiError>({
      path: `/global`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Global
   * @name PutGlobal
   * @request PUT:/global
   * @secure
   */
  putGlobal = (data: StrapiGlobalRequest, params: RequestParams = {}) =>
    this.request<StrapiGlobalResponse, StrapiError>({
      path: `/global`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Global
   * @name DeleteGlobal
   * @request DELETE:/global
   * @secure
   */
  deleteGlobal = (params: RequestParams = {}) =>
    this.request<number, StrapiError>({
      path: `/global`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
