import { Theme } from "@chakra-ui/react";

export const fonts: Partial<Theme["fonts"]> = {
  heading: "var(--font-lexend)",
  body: '"fieldwork-hum", sans-serif',
};
export const fontSizes: Partial<Theme["fontSizes"]> = {
  xs: "0.625rem", // 10pt
  sm: "0.875rem", //14 pt ~18px
  md: "1rem", //16pt ~20px
  lg: "1.125rem", //18pt 24px
  xl: "1.25rem", //20pt 26px
  "2xl": "1.5rem", //24pt 32px
  "3xl": "1.875rem", //30pt  40px
  "4xl": "2.25rem", //36pt 48px
  "5xl": "2.5rem", //40pt ~54px
  "6xl": "3rem", //48pt  64px
  "7xl": "4rem", //64pt ~86px 96px
  "8xl": "4.5rem", //72pt
  "9xl": "8rem",
};
