import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle({
  color: "white",
  fontSynthesisWeight: "thin",
  fontStyle: "italic",
  padding: "12px 16px",
  borderRadius: 10,

  fontSize: "sm",
  boxShadow: "md",
  maxW: "xs",
  zIndex: "tooltip",
  bg: "rgba(51, 51, 51, 0.8)",
});

export const ToolTip = defineStyleConfig({
  defaultProps: {
    variant: "primary",
  },

  variants: { primary },
});
