import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { components } from "./components";
import { fonts, fontSizes } from "./fonts";

export const theme = extendTheme({
  colors,
  fonts,
  fontSizes,
  components,
});
