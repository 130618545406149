import {
  createContext,
  useContext,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

type MapContextType = {
  mapSelected: boolean;
  setMapSelected: Dispatch<SetStateAction<boolean>>;
};

const MapContext = createContext<MapContextType>({
  mapSelected: false,
  setMapSelected: () => null,
});

export const MapContextProvider = ({ children }: PropsWithChildren) => {
  const [mapSelected, setMapSelected] = useState(false);

  return (
    <MapContext.Provider
      value={{
        mapSelected,
        setMapSelected,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
export const useMapContext = () => useContext(MapContext);
