import { ArrowLeft, ArrowRight } from "~/images/svgs";
import { Center, Heading, IconButton } from "@chakra-ui/react";
import { usePagination } from "~/providers";

type Props = { text?: string };

export const PaginationButtons = ({ text = "Explore more" }: Props) => {
  const { nextPage, prevPage } = usePagination();
  const scrollPoint = document.getElementById("paginationScrollIntoViewPoint");
  return (
    <>
      {(!!prevPage || !!nextPage) && (
        <Center m={{ base: "24px" }} gap={{ base: "24px" }}>
          <IconButton
            size="lg"
            aria-label="previous page"
            icon={<ArrowLeft boxSize={30} />}
            variant="ghost"
            colorScheme="gray"
            isDisabled={!prevPage}
            onClick={() => {
              prevPage && prevPage();
              scrollPoint && scrollPoint.scrollIntoView();
            }}
          />
          <Heading fontSize={{ base: "md", md: "xl" }} fontWeight="normal">
            {text}
          </Heading>
          <IconButton
            size="lg"
            aria-label="next page"
            icon={<ArrowRight boxSize={30} />}
            variant={"ghost"}
            colorScheme="gray"
            isDisabled={!nextPage}
            onClick={() => {
              nextPage && nextPage();
              scrollPoint && scrollPoint.scrollIntoView();
            }}
          />
        </Center>
      )}
    </>
  );
};
