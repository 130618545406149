import { Box } from "@chakra-ui/react";
import { forwardRef } from "react";
import {
  BlocksProps,
  StrapiBlocksVideoComponent,
  useVideoBlock,
} from "~/strapi";

type Props = BlocksProps["video_props"] & {
  block: StrapiBlocksVideoComponent;
};
export const VideoBlock = forwardRef<HTMLVideoElement, Props>(
  ({ block, children, wrapper_props, ...rest }, ref) => {
    const video = useVideoBlock(block);
    if (!video) return null;
    return (
      <Box {...wrapper_props}>
        <video
          ref={ref}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          {...rest}
        >
          <source src={video.url} type={video.mime} />
          {children}
        </video>
      </Box>
    );
  }
);

VideoBlock.displayName = "VideoBlock";
