import * as React from "react";
import { SVGProps } from "react";
const CircleX = ({ ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#211E1E"
      d="M15 28.125a13.125 13.125 0 1 1 0-26.25 13.125 13.125 0 0 1 0 26.25ZM15 30a15 15 0 1 0 0-30 15 15 0 0 0 0 30Z"
    />
    <path
      fill={props.color || "#211E1E"}
      d="M8.711 8.711a.938.938 0 0 1 1.328 0L15 13.675l4.961-4.964a.94.94 0 0 1 1.328 1.328L16.326 15l4.963 4.962a.938.938 0 1 1-1.328 1.327L15 16.326l-4.961 4.963a.938.938 0 0 1-1.328-1.328l4.963-4.96-4.963-4.962a.938.938 0 0 1 0-1.328Z"
    />
  </svg>
);
export default CircleX;
