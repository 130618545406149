import { BlocksProps, StrapiBlocksMarkdownComponent } from "~/strapi";
import { Markdown } from "./Markdown";

type Props = BlocksProps["markdown_props"] & {
  block: StrapiBlocksMarkdownComponent;
};

export const MarkdownBlock = ({ block, ...props }: Props) => {
  return <Markdown {...props}>{block.content}</Markdown>;
};
