import { Button } from "./Button";
import { Checkbox } from "./CheckBox";
import { Input } from "./Input";
import { Modal } from "./Modal";
import { ToolTip } from "./Tooltip";

export const components = {
  Button,
  Checkbox,
  Input,
  Modal,
  Tooltip: ToolTip,
};
