export type StrapiComponent = { id?: number; __component?: string };

export const isComponentType = <
  ReturnType extends Type,
  Type extends StrapiComponent = StrapiComponent
>(
  type: ReturnType["__component"],
  component?: Type
): component is ReturnType => {
  return component?.__component === type;
};
