import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

type Context = {
  currentPage: number;
  totalPages: number;
  nextPage: (() => void) | false;
  prevPage: (() => void) | false;
  setPage: Dispatch<SetStateAction<number>>;
  setTotalPages: Dispatch<SetStateAction<number>>;
};

const PaginationContext = createContext<Context>({
  currentPage: 1,
  totalPages: 1,
  nextPage: false,
  prevPage: false,
  setPage: () => {},
  setTotalPages: () => {},
});

type Provider = PropsWithChildren<{
  defaultPage?: number;
  defaultTotalPages?: number;
  totalPages?: number;
}>;

export const PaginationProvider = ({
  defaultPage,
  defaultTotalPages,
  children,
}: Provider) => {
  const [page, setPage] = useState(defaultPage || 1);
  const [totalPages, setTotalPages] = useState(defaultTotalPages || 1);
  const nextPage = useMemo(
    () =>
      page !== totalPages
        ? () => {
            setPage((p) => p + 1);
          }
        : false,
    [page, totalPages]
  );
  const prevPage = useMemo(
    () =>
      page !== 1
        ? () => {
            setPage((p) => p - 1);
          }
        : false,
    [page]
  );
  return (
    <PaginationContext.Provider
      value={{
        currentPage: page,
        totalPages,
        setTotalPages,
        setPage,
        nextPage,
        prevPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => useContext(PaginationContext);
