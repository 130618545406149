import * as React from "react";
import { SVGProps } from "react";
const ListNested = ({ ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#211E1E"}
      fillRule="evenodd"
      d="M8.438 21.563a.937.937 0 0 1 .937-.938h18.75a.938.938 0 0 1 0 1.875H9.375a.938.938 0 0 1-.938-.938Zm-3.75-7.5a.937.937 0 0 1 .937-.938h18.75a.938.938 0 0 1 0 1.875H5.625a.938.938 0 0 1-.938-.938Zm-3.75-7.5a.937.937 0 0 1 .937-.938h18.75a.937.937 0 0 1 0 1.875H1.875a.937.937 0 0 1-.938-.938Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ListNested;
