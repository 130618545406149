/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  StrapiError,
  StrapiGetListingsIdParams,
  StrapiGetListingsParams,
  StrapiItineratorListingListResponse,
  StrapiItineratorListingRequest,
  StrapiItineratorListingResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Listings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Itinerator - Listing
   * @name GetListings
   * @request GET:/listings
   * @secure
   */
  getListings = (query: StrapiGetListingsParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorListingListResponse, StrapiError>({
      path: `/listings`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Listing
   * @name PostListings
   * @request POST:/listings
   * @secure
   */
  postListings = (data: StrapiItineratorListingRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorListingResponse, StrapiError>({
      path: `/listings`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Listing
   * @name GetListingsId
   * @request GET:/listings/{id}
   * @secure
   */
  getListingsId = ({ id, ...query }: StrapiGetListingsIdParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorListingResponse, StrapiError>({
      path: `/listings/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Listing
   * @name DeleteListingsId
   * @request DELETE:/listings/{id}
   * @secure
   */
  deleteListingsId = (id: number, params: RequestParams = {}) =>
    this.request<number, StrapiError>({
      path: `/listings/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Listing
   * @name PutListingsId
   * @request PUT:/listings/{id}
   * @secure
   */
  putListingsId = (id: number, data: StrapiItineratorListingRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorListingResponse, StrapiError>({
      path: `/listings/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
