import { AspectRatio, Heading } from "@chakra-ui/react";
import { forwardRef } from "react";
import YouTube from "react-youtube";
import { BlocksProps, StrapiBlocksYouTubeComponent } from "~/strapi";

type Props = BlocksProps["youtube_props"] & {
  block: StrapiBlocksYouTubeComponent;
};

export const YouTubeVideoBlock = forwardRef<HTMLVideoElement, Props>(
  ({ block, wrapper_props }, ref) => {
    const { youTubeVideoURL } = block;
    const videoId = youTubeVideoURL && youtubeUrlToId(youTubeVideoURL);
    return (
      <AspectRatio
        ref={ref}
        {...wrapper_props}
        as={videoId ? YouTube : undefined}
        w={"100%"}
        videoId={videoId}
        opts={{ width: undefined, height: undefined }}
        ratio={16 / 9}
      >
        <Heading>Youtube video url invalid</Heading>
      </AspectRatio>
    );
  }
);

YouTubeVideoBlock.displayName = "YouTubeVideoBlock";

/** From https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
    Matches various link formats
    http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
    http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
    http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
    http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
    http://www.youtube.com/embed/0zM3nApSvMg?rel=0
    http://www.youtube.com/watch?v=0zM3nApSvMg
    http://youtu.be/0zM3nApSvMg
*/

const youtubeUrlToId = (url: string) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};
