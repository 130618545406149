/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  StrapiError,
  StrapiGetItinerariesIdParams,
  StrapiGetItinerariesParams,
  StrapiItineratorItineraryListResponse,
  StrapiItineratorItineraryRequest,
  StrapiItineratorItineraryResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Itineraries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Itinerator - Itinerary
   * @name GetItineraries
   * @request GET:/itineraries
   * @secure
   */
  getItineraries = (query: StrapiGetItinerariesParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorItineraryListResponse, StrapiError>({
      path: `/itineraries`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Itinerary
   * @name PostItineraries
   * @request POST:/itineraries
   * @secure
   */
  postItineraries = (data: StrapiItineratorItineraryRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorItineraryResponse, StrapiError>({
      path: `/itineraries`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Itinerary
   * @name GetItinerariesId
   * @request GET:/itineraries/{id}
   * @secure
   */
  getItinerariesId = ({ id, ...query }: StrapiGetItinerariesIdParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorItineraryResponse, StrapiError>({
      path: `/itineraries/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Itinerary
   * @name DeleteItinerariesId
   * @request DELETE:/itineraries/{id}
   * @secure
   */
  deleteItinerariesId = (id: number, params: RequestParams = {}) =>
    this.request<number, StrapiError>({
      path: `/itineraries/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Itinerary
   * @name PutItinerariesId
   * @request PUT:/itineraries/{id}
   * @secure
   */
  putItinerariesId = (id: number, data: StrapiItineratorItineraryRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorItineraryResponse, StrapiError>({
      path: `/itineraries/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
