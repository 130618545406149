/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  StrapiCategoryListResponse,
  StrapiCategoryRequest,
  StrapiCategoryResponse,
  StrapiError,
  StrapiGetCategoriesParams,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Categories<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Category
   * @name GetCategories
   * @request GET:/categories
   * @secure
   */
  getCategories = (query: StrapiGetCategoriesParams, params: RequestParams = {}) =>
    this.request<StrapiCategoryListResponse, StrapiError>({
      path: `/categories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Category
   * @name PostCategories
   * @request POST:/categories
   * @secure
   */
  postCategories = (data: StrapiCategoryRequest, params: RequestParams = {}) =>
    this.request<StrapiCategoryResponse, StrapiError>({
      path: `/categories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Category
   * @name GetCategoriesId
   * @request GET:/categories/{id}
   * @secure
   */
  getCategoriesId = (id: number, params: RequestParams = {}) =>
    this.request<StrapiCategoryResponse, StrapiError>({
      path: `/categories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Category
   * @name PutCategoriesId
   * @request PUT:/categories/{id}
   * @secure
   */
  putCategoriesId = (id: number, data: StrapiCategoryRequest, params: RequestParams = {}) =>
    this.request<StrapiCategoryResponse, StrapiError>({
      path: `/categories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Category
   * @name DeleteCategoriesId
   * @request DELETE:/categories/{id}
   * @secure
   */
  deleteCategoriesId = (id: number, params: RequestParams = {}) =>
    this.request<number, StrapiError>({
      path: `/categories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
