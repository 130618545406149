import { Box } from "@chakra-ui/react";
import { LargeLink, Subheading } from "~/components";
import { COMMUNITIES_ORDERED_SLUGS } from "~/lib/constants";
import { ArrowLeft, ArrowRight } from "~/images/svgs";
import { Region } from "~/pages/communities/[id]";

type Props = {
  region: Region;
};

export const EastWestButtons = ({ region }: Props) => {
  const currentPage = COMMUNITIES_ORDERED_SLUGS.findIndex(
    (c) => c.id === region.id
  );
  const indexEnd = COMMUNITIES_ORDERED_SLUGS.length - 1;
  const lastPage =
    currentPage === 0 ? null : COMMUNITIES_ORDERED_SLUGS[currentPage - 1];
  const nextPage =
    currentPage === indexEnd
      ? null
      : COMMUNITIES_ORDERED_SLUGS[currentPage + 1];
  return (
    <Box
      m={{ base: "32px", lg: "104px 32px" }}
      display="flex"
      flexDir={{ base: "column", md: "row" }}
      justifyContent={{ base: "space-between" }}
    >
      {lastPage ? (
        <Box
          display="flex"
          flexDir="column"
          marginBottom={{ base: "104px", md: "0px" }}
        >
          <Subheading
            fontSize="sm"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            Go West
          </Subheading>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <ArrowLeft />
            <LargeLink
              fontWeight="bold"
              fontSize="xl"
              href={`/communities/${lastPage.slug}`}
            >
              {lastPage.name}
            </LargeLink>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
      {nextPage ? (
        <Box display="flex" flexDir="column">
          <Subheading
            fontSize="sm"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            Continue East
          </Subheading>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <LargeLink
              fontWeight="bold"
              fontSize="xl"
              href={`/communities/${nextPage.slug}`}
            >
              {nextPage.name}
            </LargeLink>
            <ArrowRight />
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};
