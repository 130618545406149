import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ChevronLeft = createIcon({
  displayName: "ChevronLeft",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.289 3.086a.938.938 0 0 1 0 1.328L10.7 15l10.588 10.587a.938.938 0 1 1-1.328 1.327l-11.25-11.25a.938.938 0 0 1 0-1.328l11.25-11.25a.938.938 0 0 1 1.328 0Z"
      clipRule="evenodd"
    />
  ),
});
