import { SimpleGrid } from "@chakra-ui/react";
import { StrapiBlocksImageWithTextComponent } from "../data-contracts";
import { BlocksProps } from "../types";
import { Markdown } from "./Markdown";
import { Image } from "./Image";

type Props = BlocksProps["image_with_text_props"] & {
  block: StrapiBlocksImageWithTextComponent;
};

export const ImageWithTextBlock = ({
  block,
  image_props = {},
  markdown_props,
  wrapper_props,
}: Props) => {
  const { wrapper_props: img_wrapper_props, ...img_props } = image_props;
  const { image, content, caption, imagePosition } = block;
  const isRight = imagePosition === "right";
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      gap={28}
      alignItems={"center"}
      justifyContent={"center"}
      {...wrapper_props}
    >
      <Image
        transform={`rotate(${isRight ? "" : "-"}3deg)`}
        borderRadius="lg"
        maxH="80vh"
        w="100%"
        objectFit="contain"
        objectPosition="center"
        alt={caption}
        caption={caption}
        image={image}
        wrapper_props={{
          ...img_wrapper_props,
          margin: "auto",
          order: isRight ? 1 : 0,
        }}
        {...img_props}
      />

      <Markdown {...markdown_props}>{content}</Markdown>
    </SimpleGrid>
  );
};
