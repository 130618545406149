import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle({
  dialog: {
    bg: "yellow.500",
    borderRadius: 10,
    display: "flex",
    padding: "12px 16px",

    _hover: {},
  },
  element: {
    _hover: {
      borderColor: "blue.400",
    },
  },
  closeButton: {
    outline: "none",
  },
  header: {
    marginTop: 10,
    marginBottom: 10,
    textTransform: "uppercase",
  },
});

export const Modal = defineStyleConfig({
  defaultProps: {
    size: "lg",
    variant: "primary",
  },
  baseStyle: {
    field: {},
  },
  variants: { primary },
});
