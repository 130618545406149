import { DrawerProps } from "@chakra-ui/react";
import { useRef } from "react";
import { CommunitiesMap } from "~/components/CommunitiesMap";
import { useDraggable, useIsMobile } from "~/lib/hooks";
import { MenuDrawer } from "./MenuDrawer";

type Props = Omit<DrawerProps, "children">;

export const CommunitiesDrawer = ({ ...props }: Props) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const dragProps = useDraggable(scrollRef);
  const isMobile = useIsMobile();
  return (
    <MenuDrawer
      {...props}
      placement={isMobile ? "right" : "top"}
      bgImage={"/MapTexture.png"}
      bodyProps={{
        pb: 10,
        display: "flex",
        onWheel: (e) => {
          if (!scrollRef.current) return;
          const currentLeft = scrollRef.current.scrollLeft;
          scrollRef.current.scrollLeft = currentLeft + e.deltaY;
        },
        ...dragProps,
      }}
      ref={scrollRef}
    >
      <CommunitiesMap onClose={props.onClose} />
    </MenuDrawer>
  );
};
