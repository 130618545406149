import {
  Button as ChakraButton,
  ButtonProps,
  forwardRef,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from "~/images/svgs";
type ArrowButtonProps = ButtonProps & {
  direction: string;
};
export const ArrowButtons = forwardRef<ArrowButtonProps, "button">(
  ({ direction, ...props }, ref) => {
    const { children } = props;
    return (
      <ChakraButton variant="arrow" {...props} ref={ref}>
        {direction === "right" ? <ChevronRight /> : <ChevronLeft />}
        {children}
      </ChakraButton>
    );
  }
);
