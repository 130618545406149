import * as React from "react";
import { Icon, IconProps, createIcon } from "@chakra-ui/icons";

// export const Check = createIcon({
//   displayName: "Check",
//   viewBox: "0 0 20 20",
//   path: (
//     <path
//       fill="currentColor"
//       d="M17.3176 4.5574C17.3758 4.61545 17.4219 4.68442 17.4534 4.76035C17.485 4.83629 17.5012 4.91769 17.5012 4.9999C17.5012 5.08211 17.485 5.16351 17.4534 5.23944C17.4219 5.31537 17.3758 5.38434 17.3176 5.4424L8.56756 14.1924C8.5095 14.2506 8.44053 14.2968 8.3646 14.3283C8.28867 14.3598 8.20727 14.376 8.12506 14.376C8.04285 14.376 7.96145 14.3598 7.88552 14.3283C7.80958 14.2968 7.74061 14.2506 7.68256 14.1924L3.30756 9.8174C3.1902 9.70004 3.12427 9.54087 3.12427 9.3749C3.12427 9.20893 3.1902 9.04975 3.30756 8.9324C3.42492 8.81504 3.58409 8.74911 3.75006 8.74911C3.91603 8.74911 4.0752 8.81504 4.19256 8.9324L8.12506 12.8661L16.4326 4.5574C16.4906 4.49919 16.5596 4.45301 16.6355 4.42151C16.7114 4.39 16.7928 4.37378 16.8751 4.37378C16.9573 4.37378 17.0387 4.39 17.1146 4.42151C17.1905 4.45301 17.2595 4.49919 17.3176 4.5574Z"
//     />
//   ),
// });

type Props = IconProps & {
  isChecked?: boolean;
  isIndeterminate?: boolean;
};
export const Check = ({ isChecked, isIndeterminate, ...props }: Props) => {
  if (!isChecked) return null;
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        d="M17.3176 4.5574C17.3758 4.61545 17.4219 4.68442 17.4534 4.76035C17.485 4.83629 17.5012 4.91769 17.5012 4.9999C17.5012 5.08211 17.485 5.16351 17.4534 5.23944C17.4219 5.31537 17.3758 5.38434 17.3176 5.4424L8.56756 14.1924C8.5095 14.2506 8.44053 14.2968 8.3646 14.3283C8.28867 14.3598 8.20727 14.376 8.12506 14.376C8.04285 14.376 7.96145 14.3598 7.88552 14.3283C7.80958 14.2968 7.74061 14.2506 7.68256 14.1924L3.30756 9.8174C3.1902 9.70004 3.12427 9.54087 3.12427 9.3749C3.12427 9.20893 3.1902 9.04975 3.30756 8.9324C3.42492 8.81504 3.58409 8.74911 3.75006 8.74911C3.91603 8.74911 4.0752 8.81504 4.19256 8.9324L8.12506 12.8661L16.4326 4.5574C16.4906 4.49919 16.5596 4.45301 16.6355 4.42151C16.7114 4.39 16.7928 4.37378 16.8751 4.37378C16.9573 4.37378 17.0387 4.39 17.1146 4.42151C17.1905 4.45301 17.2595 4.49919 17.3176 4.5574Z"
      />
    </Icon>
  );
};
