import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const Facebook = createIcon({
  displayName: "Facebook",
  viewBox: "0 0 42 42",
  path: (
    <path
      fill="currentColor"
      d="M41.0001 20.1226C41.0001 9.00756 32.0451 -0.00244141 21.0001 -0.00244141C9.95012 5.85939e-05 0.995117 9.00756 0.995117 20.1251C0.995117 30.1676 8.31012 38.4926 17.8701 40.0026V25.9401H12.7951V20.1251H17.8751V15.6876C17.8751 10.6451 20.8626 7.86006 25.4301 7.86006C27.6201 7.86006 29.9076 8.25256 29.9076 8.25256V13.2026H27.3851C24.9026 13.2026 24.1276 14.7551 24.1276 16.3476V20.1226H29.6726L28.7876 25.9376H24.1251V40.0001C33.6851 38.4901 41.0001 30.1651 41.0001 20.1226Z"
    />
  ),
});
