export const colors = {
  black: "#000000",
  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
  },
  gray: {
    50: "#e9e9e9",
    100: "#d3d2d2",
    200: "#bcbcbc",
    300: "#a6a5a5",
    400: "#908f8f",
    500: "#7a7878",
    600: "#646262",
    700: "#4d4b4b",
    800: "#373535",
    900: "#211E1E",
  },
  red: {
    50: "#feecec",
    100: "#fed9da",
    200: "#fdb3b4",
    300: "#fb8d8f",
    400: "#fa6769",
    500: "#E53E3E",
    600: "#c73436",
    700: "#952729",
    800: "#641a1b",
    900: "#4b1314",
  },
  orange: {
    50: "#fef5e9",
    100: "#fdead2",
    200: "#fcd5a5",
    300: "#FAC178",
    400: "#f9ac4b",
    500: "#F7971E",
    600: "#c67918",
    700: "#945b12",
    800: "#633c0c",
    900: "#4a2d09",
  },
  yellow: {
    50: "#FFFCED",
    100: "#FFF8DC",
    200: "#FFF1B8",
    300: "#FFEB95",
    400: "#FFE471",
    500: "#FFDD4E",
    600: "#CCB13E",
    700: "#99852f",
    800: "#66581F",
    900: "#4C4217",
  },
  green: {
    50: "#E6F5EF",
    100: "#CCEBDE",
    200: "#99D7BD",
    300: "#66C29D",
    400: "#33AE7C",
    500: "#009A5B",
    600: "#007B49",
    700: "#005C37",
    800: "#004D2E",
    900: "#002E1B",
  },
  blue: {
    50: "#EBF4FA",
    100: "#D7E9F6",
    200: "#AFD4ED",
    300: "#88BEE3",
    400: "#60A9DA",
    500: "#3893D1",
    600: "#2D76A7",
    700: "#22587D",
    800: "#1C4A69",
    900: "#163B54",
  },
  sage: {
    50: "#F4F9F0",
    100: "#E9F2E2",
    200: "#C8DFB6",
    300: "#BCD8A7",
    400: "#A6CB8A",
    500: "#90BE6D",
    600: "#82AB62",
    700: "#739857",
    800: "#65854C",
    900: "#567241",
  },
  mountain: {
    50: "#E9F2F6",
    100: "#D4E5EC",
    200: "#A9CBD9",
    300: "#7DB1C7",
    400: "#5297B4",
    500: "#277DA1",
    600: "#237191",
    700: "#1F6481",
    800: "#174B61",
    900: "#143F51",
  },
};
