import { LinkOverlay, LinkOverlayProps } from "@chakra-ui/react";
import Link from "next/link";

export const OptionalLinkOverlay = ({
  children,
  href,
  ...props
}: LinkOverlayProps) => {
  if (!href) return children;
  return (
    <LinkOverlay as={Link} href={href} {...props}>
      {children}
    </LinkOverlay>
  );
};
