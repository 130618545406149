import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const SearchSmall = createIcon({
  displayName: "SearchSmall ",
  viewBox: "0 0 21 21",
  path: (
    <path
      d="M15.6774 13.4299C16.8878 11.7782 17.4299 9.73049 17.1953 7.69632C16.9607 5.66214 15.9667 3.79155 14.4121 2.45879C12.8575 1.12603 10.8571 0.429386 8.81095 0.508227C6.76481 0.587069 4.82388 1.43558 3.37649 2.88402C1.92909 4.33245 1.08196 6.27398 1.00459 8.32017C0.92721 10.3664 1.62529 12.3663 2.95916 13.9199C4.29303 15.4735 6.16433 16.4662 8.19868 16.6994C10.233 16.9325 12.2804 16.3889 13.9312 15.1774H13.9299C13.9674 15.2274 14.0074 15.2749 14.0524 15.3211L18.8649 20.1336C19.0993 20.3682 19.4172 20.5 19.7488 20.5001C20.0804 20.5003 20.3985 20.3686 20.633 20.1343C20.8676 19.8999 20.9994 19.5819 20.9995 19.2503C20.9996 18.9187 20.868 18.6007 20.6337 18.3661L15.8212 13.5536C15.7765 13.5084 15.7284 13.4679 15.6774 13.4299ZM15.9999 8.62488C15.9999 9.52772 15.8221 10.4217 15.4766 11.2558C15.1311 12.0899 14.6247 12.8478 13.9863 13.4862C13.3479 14.1246 12.59 14.6311 11.7558 14.9766C10.9217 15.3221 10.0277 15.4999 9.1249 15.4999C8.22206 15.4999 7.32807 15.3221 6.49395 14.9766C5.65984 14.6311 4.90195 14.1246 4.26354 13.4862C3.62514 12.8478 3.11873 12.0899 2.77323 11.2558C2.42773 10.4217 2.2499 9.52772 2.2499 8.62488C2.2499 6.80152 2.97423 5.05283 4.26354 3.76352C5.55286 2.47421 7.30154 1.74988 9.1249 1.74988C10.9483 1.74988 12.6969 2.47421 13.9863 3.76352C15.2756 5.05283 15.9999 6.80152 15.9999 8.62488Z"
      fill="currentColor"
    />
  ),
});
