/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  StrapiError,
  StrapiGetPagesIdParams,
  StrapiGetPagesParams,
  StrapiItineratorPageListResponse,
  StrapiItineratorPageRequest,
  StrapiItineratorPageResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Pages<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Itinerator - Page
   * @name GetPages
   * @request GET:/pages
   * @secure
   */
  getPages = (query: StrapiGetPagesParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorPageListResponse, StrapiError>({
      path: `/pages`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Page
   * @name PostPages
   * @request POST:/pages
   * @secure
   */
  postPages = (data: StrapiItineratorPageRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorPageResponse, StrapiError>({
      path: `/pages`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Page
   * @name GetPagesId
   * @request GET:/pages/{id}
   * @secure
   */
  getPagesId = ({ id, ...query }: StrapiGetPagesIdParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorPageResponse, StrapiError>({
      path: `/pages/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Page
   * @name DeletePagesId
   * @request DELETE:/pages/{id}
   * @secure
   */
  deletePagesId = (id: number, params: RequestParams = {}) =>
    this.request<number, StrapiError>({
      path: `/pages/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Page
   * @name PutPagesId
   * @request PUT:/pages/{id}
   * @secure
   */
  putPagesId = (id: number, data: StrapiItineratorPageRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorPageResponse, StrapiError>({
      path: `/pages/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
