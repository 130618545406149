import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const Share = createIcon({
  displayName: "Share",
  viewBox: "0 0 20 20",
  path: (
    <path
      fill="currentColor"
      d="M14.82 13.308c-.61 0-1.157.241-1.574.619l-5.727-3.334c.04-.184.072-.37.072-.562a2.63 2.63 0 0 0-.072-.562l5.662-3.301a2.4 2.4 0 0 0 1.639.65 2.406 2.406 0 0 0 2.41-2.41A2.406 2.406 0 0 0 14.82 2a2.406 2.406 0 0 0-2.41 2.41c0 .192.032.377.073.562L6.82 8.272a2.4 2.4 0 0 0-1.639-.65 2.406 2.406 0 0 0-2.41 2.41 2.406 2.406 0 0 0 2.41 2.409 2.4 2.4 0 0 0 1.639-.65l5.719 3.34c-.04.17-.064.346-.064.523a2.348 2.348 0 0 0 2.345 2.345 2.348 2.348 0 0 0 2.345-2.345 2.348 2.348 0 0 0-2.345-2.346Z"
    />
  ),
});
