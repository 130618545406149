import * as React from "react";
import { SVGProps } from "react";
const CloudSun = ({ ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#211E1E"}
      d="M14.125 15a6.563 6.563 0 0 1 6.563 6.666.938.938 0 0 0 1.17.922 2.82 2.82 0 0 1 3.517 2.725 2.812 2.812 0 0 1-2.812 2.812H6.625a3.75 3.75 0 1 1 .188-7.496.937.937 0 0 0 .956-.703A6.566 6.566 0 0 1 14.125 15Zm8.387 5.625a8.438 8.438 0 0 0-16.35-1.856A5.625 5.625 0 0 0 6.625 30h15.938a4.688 4.688 0 0 0 0-9.375h-.051Z"
    />
    <path
      fill={props.color || "#211E1E"}
      d="M20.688 2.813a.937.937 0 1 0-1.875 0v1.874a.938.938 0 0 0 1.875 0V2.813Zm7.018 3.682a.94.94 0 0 0-.297-1.542.938.938 0 0 0-1.029.216l-1.328 1.326a.939.939 0 0 0 1.328 1.327l1.326-1.327ZM13.12 5.169a.937.937 0 0 0-1.326 1.326l1.326 1.327a.94.94 0 0 0 1.328-1.327L13.12 5.169Zm3.251 6.327a3.75 3.75 0 1 1 6.18 4.12c.373.528.698 1.092.968 1.685a5.624 5.624 0 1 0-9.075-6.047c.66.02 1.305.103 1.927.242Zm8.408 7.638a6.579 6.579 0 0 1 2.854 2.01.937.937 0 0 0 .073-1.39l-1.326-1.327a.937.937 0 0 0-1.601.707Zm3.409-6.947a.938.938 0 0 0 0 1.876h1.875a.938.938 0 0 0 0-1.876h-1.875Z"
    />
  </svg>
);
export default CloudSun;
