import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const Instagram = createIcon({
  displayName: "Instagram",
  viewBox: "0 0 20 20",
  path: (
    <path
      fill="currentColor"
      d="M10 0C7.286 0 6.945.013 5.879.06 4.813.11 4.086.278 3.45.525a4.896 4.896 0 0 0-1.771 1.154A4.909 4.909 0 0 0 .525 3.45C.278 4.085.109 4.812.06 5.875.012 6.944 0 7.284 0 10.001c0 2.715.013 3.055.06 4.122.05 1.065.218 1.79.465 2.427a4.91 4.91 0 0 0 1.154 1.771 4.88 4.88 0 0 0 1.77 1.154c.637.247 1.362.416 2.427.465 1.068.047 1.408.06 4.124.06 2.716 0 3.055-.012 4.123-.06 1.063-.05 1.792-.218 2.428-.465a4.893 4.893 0 0 0 1.77-1.154 4.91 4.91 0 0 0 1.154-1.771c.246-.636.415-1.363.465-2.428.047-1.066.06-1.406.06-4.122s-.012-3.056-.06-4.124c-.05-1.064-.219-1.791-.465-2.426a4.907 4.907 0 0 0-1.154-1.771A4.888 4.888 0 0 0 16.55.525C15.912.278 15.185.109 14.121.06 13.054.012 12.715 0 9.998 0H10 10Zm-.896 1.802H10c2.67 0 2.987.01 4.04.058.975.044 1.505.207 1.858.344.466.181.8.398 1.15.748.35.35.566.683.747 1.15.138.352.3.882.344 1.857.049 1.054.059 1.37.059 4.039 0 2.668-.01 2.986-.059 4.04-.044.974-.207 1.503-.344 1.856a3.09 3.09 0 0 1-.748 1.149c-.35.35-.683.566-1.15.747-.35.137-.88.3-1.857.345-1.053.047-1.37.059-4.04.059s-2.987-.011-4.041-.059c-.975-.045-1.504-.208-1.856-.345a3.097 3.097 0 0 1-1.15-.747 3.1 3.1 0 0 1-.75-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.053-.058-1.37-.058-4.041 0-2.67.01-2.985.058-4.039.045-.975.207-1.505.345-1.857.181-.467.399-.8.749-1.15a3.09 3.09 0 0 1 1.15-.748c.352-.137.881-.3 1.856-.345.923-.042 1.28-.055 3.144-.056v.002Zm6.235 1.66a1.2 1.2 0 1 0 0 2.401 1.2 1.2 0 0 0 0-2.4ZM10 4.865a5.136 5.136 0 1 0-.16 10.271 5.136 5.136 0 0 0 .16-10.271Zm0 1.801a3.334 3.334 0 1 1 0 6.668 3.334 3.334 0 0 1 0-6.668Z"
    />
  ),
});
