import {
  Flex,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  MenuProps,
  PlacementWithLogical,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronUp,
} from "@tabler/icons-react";
import { PropsWithChildren, ReactNode, useState } from "react";

type Props = PropsWithChildren &
  MenuProps & {
    name: ReactNode;
    menu_button_props?: MenuButtonProps & MenuItemProps;
    menu_list_props?: MenuListProps;
  };

export const SubMenu = ({
  name,
  menu_button_props,
  menu_list_props,
  children,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);
  const closeSubmenu = () => {
    if (open) {
      setOpen(false);
    }
  };

  const toggleSubmenu = () => {
    setOpen(!open);
  };

  const placement = useBreakpointValue<PlacementWithLogical>({
    base: "bottom",
    md: "start-end",
  });

  return (
    <Menu
      isOpen={open}
      placement={placement}
      onClose={closeSubmenu}
      {...props}
      strategy="fixed"
      matchWidth={placement === "bottom"}
    >
      <MenuButton
        as={MenuItem}
        onClick={toggleSubmenu}
        {...menu_button_props}
        icon={placement !== "bottom" ? <IconChevronLeft /> : undefined}
      >
        <Flex align="center" justify="space-between">
          {name}
          {placement === "bottom" &&
            (open ? <IconChevronUp /> : <IconChevronDown />)}
        </Flex>
      </MenuButton>
      <MenuList maxW={"100vw"} {...menu_list_props}>
        {children}
      </MenuList>
    </Menu>
  );
};
