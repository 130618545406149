import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { MaybeRenderProp } from "@chakra-ui/react-types";
import { runIfFn } from "@chakra-ui/shared-utils";
import {
  Splide,
  SplideProps,
  SplideSlide,
  SplideTrack,
} from "@splidejs/react-splide";
import {
  Children,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useResizeObserver } from "usehooks-ts";

export type SliderProps = Omit<SplideProps, "children"> & {
  wrapper_props?: FlexProps;
  children?: MaybeRenderProp<{ splide: Splide | null; activeIndex: number }>;
  before?: ReactNode;
  after?: ReactNode;
};

type SliderContextType = {
  splide: Splide | null;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setSplide: Dispatch<SetStateAction<Splide | null>>;
};

const SliderContext = createContext<SliderContextType>({
  splide: null,
  activeIndex: 0,
  setActiveIndex: () => {},
  setSplide: () => {},
});

type SlideContextType = {
  isActive: boolean;
  isNext: boolean;
  isPrev: boolean;
  isLast: boolean;
  index: number;
  goTo: () => void;
};

const SlideContext = createContext<SlideContextType>({
  isActive: false,
  isNext: false,
  isPrev: false,
  isLast: false,
  index: 0,
  goTo: () => {},
});

export const useSlideContext = () => useContext(SlideContext);
export const useSliderContext = () => useContext(SliderContext);

export const Slider = ({
  onActive,
  options,
  children,
  wrapper_props,
  before,
  after,
  ...props
}: SliderProps) => {
  const splideRef = useRef<Splide>(null);
  const { activeIndex, setActiveIndex, setSplide } = useSliderContext();
  // const [boxRef, { height }] = useElementSize();
  const boxRef = useRef<HTMLDivElement>(null);
  const { height = 0 } = useResizeObserver({ ref: boxRef, box: "border-box" });
  const splide = splideRef.current;
  const arrayChildren = Children.toArray(
    runIfFn(children, { splide: splide, activeIndex })
  );
  useEffect(() => {
    if (splideRef.current) {
      setSplide(splideRef.current);
    }
  }, [setSplide, splideRef]);
  useEffect(() => {
    splideRef.current?.go(activeIndex);
  }, [activeIndex]);
  return (
    <Flex
      direction={options?.direction === "ttb" ? "column" : "row"}
      boxSize="full"
      {...wrapper_props}
    >
      {before}
      <Box flexGrow={1} ref={boxRef} overflow={"hidden"}>
        <Splide
          hasTrack={false}
          ref={splideRef}
          onActive={(splide, slide: any) => {
            setActiveIndex(slide.index);
            onActive && onActive(splide, slide);
          }}
          options={{
            height: (height && `${height}px`) || "auto",
            wheel: true,
            wheelMinThreshold: 50,
            wheelSleep: 800,
            waitForTransition: true,
            pauseOnHover: true,
            keyboard: true,
            pagination: false,
            arrows: false,
            trimSpace: false,
            ...options,
          }}
          {...props}
        >
          <SplideTrack>
            {Children.map(arrayChildren, (child, index) => (
              <SlideContext.Provider
                value={{
                  isActive: index === activeIndex,
                  isPrev: index === activeIndex - 1,
                  isNext: index === activeIndex + 1,
                  isLast: index === arrayChildren.length - 1,
                  index,
                  goTo: () => splide?.go(index),
                }}
              >
                <SplideSlide>{child}</SplideSlide>
              </SlideContext.Provider>
            ))}
          </SplideTrack>
        </Splide>
      </Box>
      {after}
    </Flex>
  );
};

export const SliderProvider = ({ children }: PropsWithChildren) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [splide, setSplide] = useState<Splide | null>(null);

  return (
    <SliderContext.Provider
      value={{ activeIndex, setActiveIndex, splide, setSplide }}
    >
      {children}
    </SliderContext.Provider>
  );
};
