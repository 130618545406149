import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ArrowRight = createIcon({
  displayName: "ArrowRight",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 15a.937.937 0 0 1 .938-.937h10.861l-4.025-4.024A.939.939 0 0 1 16.6 8.711l5.625 5.625a.936.936 0 0 1 0 1.328l-5.625 5.625a.939.939 0 1 1-1.327-1.328l4.025-4.023H8.438A.938.938 0 0 1 7.5 15Z"
      clipRule="evenodd"
    />
  ),
});
