import { createIcon } from "@chakra-ui/icons";

export const PinSolid = createIcon({
  displayName: "PinSolid",
  viewBox: "0 0 15 15",
  path: (
    <>
      <path
        fill="currentColor"
        d="M7.5 15C7.5 15 13.125 9.66938 13.125 5.625C13.125 4.13316 12.5324 2.70242 11.4775 1.64752C10.4226 0.592632 8.99184 0 7.5 0C6.00816 0 4.57742 0.592632 3.52252 1.64752C2.46763 2.70242 1.875 4.13316 1.875 5.625C1.875 9.66938 7.5 15 7.5 15ZM7.5 8.4375C6.75408 8.4375 6.03871 8.14118 5.51126 7.61374C4.98382 7.08629 4.6875 6.37092 4.6875 5.625C4.6875 4.87908 4.98382 4.16371 5.51126 3.63626C6.03871 3.10882 6.75408 2.8125 7.5 2.8125C8.24592 2.8125 8.96129 3.10882 9.48874 3.63626C10.0162 4.16371 10.3125 4.87908 10.3125 5.625C10.3125 6.37092 10.0162 7.08629 9.48874 7.61374C8.96129 8.14118 8.24592 8.4375 7.5 8.4375Z"
      />
    </>
  ),
});
