import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { createContext, useContext, PropsWithChildren } from "react";

const LIBRARIES: Libraries = ["geometry"];

if (!process.env.NEXT_PUBLIC_GOOGLE_API_KEY)
  throw new Error("NEXT_PUBLIC_GOOGLE_API_KEY env variable missing.");

const MAP_SETTINGS = {
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  libraries: LIBRARIES,
  mapIds: ["581411231e02f215"],
};

type MapLoaderContextType = {
  mapLoaded: boolean;
};

const MapLoaderContext = createContext<MapLoaderContextType>({
  mapLoaded: false,
});

export const MapLoaderProvider = ({ children }: PropsWithChildren) => {
  const { isLoaded, loadError } = useJsApiLoader(MAP_SETTINGS);

  return (
    <MapLoaderContext.Provider value={{ mapLoaded: isLoaded }}>
      {isLoaded && children}
    </MapLoaderContext.Provider>
  );
};
export const useMapLoader = () => useContext(MapLoaderContext);
