import {
  StrapiCategoryListResponseDataItem,
  StrapiGetStoriesParams,
  StrapiStoryListResponse,
  StrapiStoryListResponseDataItem,
  useStories,
} from "~/strapi";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePagination } from "./PaginationProvider";
import { keepPreviousData } from "@tanstack/react-query";
import { get } from "lodash";

type StoriesContextType = {
  categories: StrapiCategoryListResponseDataItem[];
  stories: StrapiStoryListResponseDataItem[];
  setFilters: Dispatch<SetStateAction<StrapiGetStoriesParams["filters"]>>;
};

const StoriesContext = createContext<StoriesContextType>({
  categories: [],
  stories: [],
  setFilters: () => {},
});

type ProviderProps = PropsWithChildren<{
  categories: StrapiCategoryListResponseDataItem[];
  stories: StrapiStoryListResponse;
}>;
export const StoriesProvider = ({
  stories,
  categories,
  ...props
}: ProviderProps) => {
  const [filters, setFilters] = useState<StrapiGetStoriesParams["filters"]>({});
  const { currentPage, setPage, setTotalPages } = usePagination();
  const { data } = useStories(
    {
      params: {
        filters,
        "pagination[page]": currentPage,
        "pagination[pageSize]": 9,
      },
    },
    {
      initialData: stories,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
    }
  );
  const page = get(data, "meta.pagination.page");
  const pageCount = get(data, "meta.pagination.pageCount");
  useEffect(() => {
    setPage((p) => page || p);
  }, [page, setPage]);
  useEffect(() => {
    setTotalPages((p) => pageCount || p);
  }, [pageCount, setTotalPages]);

  return (
    <StoriesContext.Provider
      value={{
        stories: data?.data || [],
        setFilters,
        categories,
      }}
      {...props}
    />
  );
};

export const useStoriesContext = () => useContext(StoriesContext);
