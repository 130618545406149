import { Box, BoxProps, Button, Flex, Image, Text } from "@chakra-ui/react";
import { ItineratorStop, isListingStop } from "@itinerator/next-package";
import { Pin } from "~/images/svgs";
import { useSlideContext } from "../Slider";

type MapStopCardProps = BoxProps & {
  stop: ItineratorStop;
};
export const MapStopCard = ({ stop, ...props }: MapStopCardProps) => {
  const { lead_image, description: stop_description } = stop;
  const listing = isListingStop(stop) ? stop.target : null;
  const { index, isLast, isActive } = useSlideContext();

  const image = lead_image?.url || listing?.lead_image?.url;
  const description = stop_description || listing?.short_description;
  return (
    <Box p={5} bgColor={"white"} borderBottomRadius={isLast ? "3xl" : "none"}>
      <Box
        borderRadius={"md"}
        bgColor={!isActive || (index === 0 && isLast) ? "white" : "yellow.100"}
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        alignItems="stretch"
        w="100%"
        p={3}
        minH={{ base: "402px", md: "268px" }}
        gap={{ base: "16px", md: "25px" }}
        {...props}
      >
        <Image
          w={{ base: "100%", md: "40%" }}
          h={{ base: "138px", md: "auto" }}
          borderRadius="5px"
          src={image}
          fallbackSrc="/StopFillerImage.jpg"
          alt="Stop Image"
          objectFit="cover"
        />
        <Flex direction="column" w={{ base: "100%" }} gap={{ base: 2, md: 2 }}>
          <Text fontSize={{ base: "lg" }}>{stop.name}</Text>

          {listing?.city && (
            <Box display="flex" alignItems="center">
              <Text
                fontSize={{ base: "sm" }}
                fontWeight="thin"
                fontStyle="italic"
              >
                <Pin marginBottom={1} marginRight={1} />
                {listing.city}
              </Text>
            </Box>
          )}
          {!!description && (
            <Text
              noOfLines={{ base: 4 }}
              fontWeight="thin"
              fontSize={{ base: "sm" }}
            >
              {description}
            </Text>
          )}
          {listing?.website && (
            <Flex flexGrow={1} align="end">
              <Button size="sm" as="a" href={listing.website} target="_blank">
                Learn More
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
