import * as React from "react";
import { SVGProps } from "react";
const FilterLeft = ({ ...props }: SVGProps<any>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <path
        fill={props.color || "#211E1E"}
        d="M3.75 19.688a.937.937 0 0 1 .938-.938h5.625a.938.938 0 0 1 0 1.875H4.686a.938.938 0 0 1-.937-.938Zm0-5.625a.937.937 0 0 1 .938-.938h13.125a.938.938 0 0 1 0 1.875H4.688a.938.938 0 0 1-.938-.938Zm0-5.626a.937.937 0 0 1 .938-.937h20.625a.937.937 0 0 1 0 1.875H4.688a.937.937 0 0 1-.938-.938Z"
      />
    </svg>
  );
};
export default FilterLeft;
