import * as React from "react";
import { SVGProps } from "react";
const FilterCenter = ({ ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#211E1E"}
      d="M11.25 19.688a.938.938 0 0 1 .938-.938h5.624a.938.938 0 0 1 0 1.875h-5.625a.938.938 0 0 1-.937-.938ZM7.5 14.063a.937.937 0 0 1 .938-.938h13.124a.938.938 0 0 1 0 1.875H8.438a.938.938 0 0 1-.937-.938ZM3.75 8.436a.937.937 0 0 1 .938-.937h20.625a.937.937 0 0 1 0 1.875H4.688a.937.937 0 0 1-.938-.938Z"
    />
  </svg>
);
export default FilterCenter;
