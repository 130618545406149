/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  StrapiError,
  StrapiGetRegionsIdParams,
  StrapiGetRegionsParams,
  StrapiItineratorRegionListResponse,
  StrapiItineratorRegionRequest,
  StrapiItineratorRegionResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Regions<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Itinerator - Region
   * @name GetRegions
   * @request GET:/regions
   * @secure
   */
  getRegions = (query: StrapiGetRegionsParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorRegionListResponse, StrapiError>({
      path: `/regions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Region
   * @name PostRegions
   * @request POST:/regions
   * @secure
   */
  postRegions = (data: StrapiItineratorRegionRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorRegionResponse, StrapiError>({
      path: `/regions`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Region
   * @name GetRegionsId
   * @request GET:/regions/{id}
   * @secure
   */
  getRegionsId = ({ id, ...query }: StrapiGetRegionsIdParams, params: RequestParams = {}) =>
    this.request<StrapiItineratorRegionResponse, StrapiError>({
      path: `/regions/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Region
   * @name DeleteRegionsId
   * @request DELETE:/regions/{id}
   * @secure
   */
  deleteRegionsId = (id: number, params: RequestParams = {}) =>
    this.request<number, StrapiError>({
      path: `/regions/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Itinerator - Region
   * @name PutRegionsId
   * @request PUT:/regions/{id}
   * @secure
   */
  putRegionsId = (id: number, data: StrapiItineratorRegionRequest, params: RequestParams = {}) =>
    this.request<StrapiItineratorRegionResponse, StrapiError>({
      path: `/regions/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
