import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  field: {
    bg: "white",
    borderColor: "gray.400",
    borderWidth: 1.5,
    borderRadius: 8,
    paddingX: 4,
    paddingY: 3,
  },
});

export const Input = defineMultiStyleConfig({
  variants: { outline },
  defaultProps: {
    size: "lg",
    //@ts-expect-error charka typing only supports a few props, but all the other props work.
    focusBorderColor: "blue.400",
    errorBorderColor: "red.400",
  },
});
