import { Box, Image as CImage, Text } from "@chakra-ui/react";
import { getStrapiMedia } from "../helpers";
import { ImageComponentProps, StrapiMedia } from "../types";
import { forwardRef } from "react";

type Props = ImageComponentProps & {
  image?: StrapiMedia;
  caption?: string;
};

export const Image = forwardRef<HTMLImageElement, Props>(
  ({ image, caption, wrapper_props, caption_props, ...props }, ref) => {
    const media = getStrapiMedia(image);
    if (!media.url) return null;
    return (
      <Box {...wrapper_props}>
        <CImage
          ref={ref}
          borderRadius={"lg"}
          src={media.url}
          alt={caption}
          maxH={"80vh"}
          w={"100%"}
          objectFit={"cover"}
          objectPosition={"center"}
          {...props}
        />
        {!!caption && (
          <Text ml={10} mt={4} size="sm" fontStyle="italic" {...caption_props}>
            {caption}
          </Text>
        )}
      </Box>
    );
  }
);

Image.displayName = "Image";
