import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const Asterisk = createIcon({
  displayName: "Asterisk",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="#FFDD4E"
      fillRule="evenodd"
      d="M5.83043 8.28261C5.71304 8.43913 4.42174 10.3957 4.42174 10.5913C4.18696 10.9435 4.34348 11.1391 3.9913 11.5304H3.87391C3.6 11.5304 2.11304 11.3348 2.11304 10.9043C2.11304 10.8261 4.06956 7.65652 4.18696 7.42174C3.28696 6.83478 1.44783 6.2087 0.93913 5.77826C1.01739 5.26957 1.25217 3.9 1.68261 3.66522C2.62174 3.9 4.03043 4.87826 4.81304 5.50435C4.77391 4.72174 4.65652 3.9 4.65652 3.07826C4.65652 2.88261 4.73478 2.72609 4.73478 2.53044C4.73478 2.33478 4.61739 2.17826 4.61739 1.98261C4.61739 1.90435 4.73478 1.63044 4.73478 1.55218C5.20435 1.31739 5.7913 1.16087 6.3 1.16087C6.37826 1.16087 6.6913 1.2 6.6913 1.35652C6.6913 2.02174 6.53478 2.64783 6.53478 3.31304C6.53478 3.70435 6.61304 4.09565 6.61304 4.48696C6.61304 4.87826 6.57391 5.26957 6.57391 5.66087C7.63043 4.95652 8.64783 4.29131 9.70435 3.62609C9.9 3.93913 10.5652 4.87826 10.5652 5.1913C10.5652 5.73913 8.02174 6.95217 7.55217 7.22609C8.13913 7.77391 9.78261 9.88696 9.78261 10.7478C9.78261 10.8261 9.78261 10.8261 9.74348 10.9043C9.46956 11.1 8.72609 11.6478 8.41304 11.6478H8.33478C7.47391 10.5522 6.73043 9.37826 5.83043 8.28261Z"
      clipRule="evenodd"
    />
  ),
});
