/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  StrapiError,
  StrapiGetStoriesIdParams,
  StrapiGetStoriesParams,
  StrapiStoryListResponse,
  StrapiStoryRequest,
  StrapiStoryResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Stories<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Story
   * @name GetStories
   * @request GET:/stories
   * @secure
   */
  getStories = (query: StrapiGetStoriesParams, params: RequestParams = {}) =>
    this.request<StrapiStoryListResponse, StrapiError>({
      path: `/stories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Story
   * @name PostStories
   * @request POST:/stories
   * @secure
   */
  postStories = (data: StrapiStoryRequest, params: RequestParams = {}) =>
    this.request<StrapiStoryResponse, StrapiError>({
      path: `/stories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Story
   * @name GetStoriesId
   * @request GET:/stories/{id}
   * @secure
   */
  getStoriesId = ({ id, ...query }: StrapiGetStoriesIdParams, params: RequestParams = {}) =>
    this.request<StrapiStoryResponse, StrapiError>({
      path: `/stories/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Story
   * @name PutStoriesId
   * @request PUT:/stories/{id}
   * @secure
   */
  putStoriesId = (id: number, data: StrapiStoryRequest, params: RequestParams = {}) =>
    this.request<StrapiStoryResponse, StrapiError>({
      path: `/stories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Story
   * @name DeleteStoriesId
   * @request DELETE:/stories/{id}
   * @secure
   */
  deleteStoriesId = (id: number, params: RequestParams = {}) =>
    this.request<number, StrapiError>({
      path: `/stories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
