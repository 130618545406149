import { get } from "lodash";
import Head from "next/head";
import { useRouter } from "next/router";
import { StrapiSharedSeoComponent } from "~/strapi";

type Props = {
  seo?: StrapiSharedSeoComponent;
};

export const Seo = ({ seo = {} }: Props) => {
  const { pathname } = useRouter();

  const {
    metaTitle = "Highway 3",
    metaDescription = "Official website for travel planning Highway 3 from Hope to Elkford, BC",
    metaImage,
    structuredData,
    metaSocial,
    canonicalURL,
  } = seo;

  const url =
    typeof window === "undefined"
      ? `https://hwy3bc.ca${pathname}`
      : window.location.href;
  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} key="description" />

      <meta property="og:url" content={url} key="og:url" />
      <meta property="og:title" content={metaTitle} key="og:title" />
      <meta
        property="og:description"
        content={metaDescription}
        key="og:description"
      />
      <meta
        property="og:image"
        content={get(metaImage, "data.attributes.url", "/meta_image.jpg")}
        key="og:image"
      />
      <meta property="og:type" content="website" />
      <link rel="canonical" href={"url"} />
      {!!metaSocial?.length &&
        metaSocial.map((meta) => {
          const key = meta.socialNetwork === "Twitter" ? "twitter" : "fb";
          return (
            <>
              <meta name={`${key}:title`} content={meta.title} />
              <meta name={`${key}:description`} content={meta.description} />
              <meta
                name={`${key}:image`}
                content={meta.image?.data?.attributes?.url}
              />
              <meta
                name={`${key}:image:alt`}
                content={meta.image?.data?.attributes?.caption}
              />
            </>
          );
        })}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
    </Head>
  );
};
