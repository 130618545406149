import { Heading, HeadingProps, forwardRef } from "@chakra-ui/react";

export const Subheading = forwardRef<HeadingProps, "h2">(
  (props: HeadingProps, ref) => (
    <Heading
      ref={ref}
      size="md"
      fontFamily={"var(--font-greatForest)"}
      color="gray.900"
      {...props}
      fontWeight={400} //When using Great Forest, font weight should always be 'normal' or 400. Anything above 500 will add artificial (browser generated) boldness.
    />
  )
);
