import { useBreakpointValue } from "@chakra-ui/react";
import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { RecursiveArray } from "lodash";
import { useEffect, useState } from "react";
import { APIStoriesParams } from "~/pages/api/stories";
import { RecursiveRequired } from "~/types";
import {
  StrapiBlocksVideoComponent,
  StrapiGetStoriesParams,
  StrapiStoryListResponse,
} from "./data-contracts";
import { PopulateObject, StrapiMedia, VideoBlockVideoKey } from "./types";

export const getStrapiMedia = (media?: StrapiMedia) =>
  hasStrapiMedia(media) ? media.data.attributes : {};

export const hasStrapiMedia = (
  media?: StrapiMedia
): media is RecursiveRequired<StrapiMedia, 1> => !!media?.data?.attributes;

export const hasVideoBlock = (
  videoBlock?: StrapiBlocksVideoComponent
): videoBlock is RecursiveRequired<StrapiBlocksVideoComponent> => {
  return (
    !!videoBlock &&
    !!VIDEO_BLOCK_VIDEO_KEYS.find((key) => hasStrapiMedia(videoBlock[key]))
  );
};

export const useVideoBlock = (videoBlock?: StrapiBlocksVideoComponent) => {
  const videoSize = useBreakpointValue<VideoBlockVideoKey>(
    {
      base: "small",
      md: "medium",
      lg: "large",
      xl: "xLarge",
    },
    { ssr: false }
  )!;
  const [video, setVideo] =
    useState<Required<StrapiMedia>["data"]["attributes"]>();
  useEffect(() => {
    if (hasVideoBlock(videoBlock)) {
      const preferredSizeIndex = VIDEO_BLOCK_VIDEO_KEYS.indexOf(videoSize);
      for (let i = 0, len = VIDEO_BLOCK_VIDEO_KEYS.length; i < len; i++) {
        const index = i + preferredSizeIndex;
        const nextIndex = index < len ? index : len - 1 - i;
        const video = videoBlock[VIDEO_BLOCK_VIDEO_KEYS[nextIndex]];
        if (hasStrapiMedia(video)) {
          setVideo(getStrapiMedia(video));
          break;
        }
      }
    } else {
      setVideo(undefined);
    }
  }, [videoSize, videoBlock]);
  return video;
};

export const VIDEO_BLOCK_VIDEO_KEYS: VideoBlockVideoKey[] = [
  "small",
  "medium",
  "large",
  "xLarge",
];

const createKey = (array: string[], join: string = ".") =>
  array.filter(Boolean).join(join);

const getPopulateObjectString = (
  object: PopulateObject,
  key: string
): RecursiveArray<string> | string => {
  return Object.keys(object).map((k) => {
    const newKey = createKey([key, k]);
    const value = object[k];
    if (typeof value === "string") return createKey([newKey, value]);
    if (Array.isArray(value))
      return value.map((val) =>
        typeof val === "string"
          ? createKey([newKey, val])
          : getPopulateObjectString(val, newKey)
      );
    return getPopulateObjectString(value, newKey);
  });
};

export const toPopulateString = (array: (PopulateObject | string)[]) => {
  //@ts-ignore
  return array
    .map((item) =>
      typeof item === "string" ? item : getPopulateObjectString(item, "")
    )
    .flat(Infinity)
    .join(",");
};

export type GetApiStoriesParams = Omit<APIStoriesParams, "params"> & {
  params?: StrapiGetStoriesParams;
};

const getAPIStories = ({
  recordId,
  pageId,
  recordType,
  params,
}: GetApiStoriesParams) =>
  axios
    .get<StrapiStoryListResponse>("/api/stories", {
      params: {
        recordId,
        recordType,
        pageId,
        params: JSON.stringify({
          ...params,
          discriminator: "STRAPI-STORY-PARAMS",
        }),
      },
    })
    .then(({ data }) => data);

export const useStories = (
  params: GetApiStoriesParams = {},
  options?: Omit<
    UseQueryOptions<
      StrapiStoryListResponse,
      unknown,
      StrapiStoryListResponse,
      QueryKey
    >,
    "queryKey" | "queryFn"
  >
) =>
  useQuery({
    queryKey: ["stories", params],
    queryFn: () => getAPIStories(params),
    ...options,
  });
