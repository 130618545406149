import * as React from "react";
import { createIcon } from "@chakra-ui/icons";

export const Phone = createIcon({
  displayName: "Phone",
  viewBox: "0 0 266 266",
  path: (
    <path d="M223.88,183.08A56.26,56.26,0,0,1,168,232C88.6,232,24,167.4,24,88A56.26,56.26,0,0,1,72.92,32.12a16,16,0,0,1,16.62,9.52l21.12,47.15a.61.61,0,0,0,0,.12A16,16,0,0,1,109.39,104c-.18.27-.37.52-.57.77L88,129.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.18-1.4l.12.06,47.1,21.11A16,16,0,0,1,223.88,183.08ZM144,72h64a8,8,0,0,0,0-16H144a8,8,0,0,0,0,16Zm0,40h64a8,8,0,0,0,0-16H144a8,8,0,0,0,0,16Z"></path>
  ),
});
