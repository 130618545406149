import {
  Heading,
  Image,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { ReactMarkdown as RMarkdown } from "react-markdown/lib/react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Subheading } from "./Subheading";
import { ReactMarkdownProps } from "~/types";
import { Link } from "@chakra-ui/next-js";

export const ReactMarkdown = ({
  components,
  textStyle,
  textProps,
  linkProps,
  format_children = true,
  children,
  ...props
}: ReactMarkdownProps) => {
  if (!children) return null;
  return (
    <RMarkdown
      remarkPlugins={[gfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: ({ node, ...props }) => (
          <Heading textStyle="h1" mt={4} mb={3} {...props} {...textProps} />
        ),
        h2: ({ node, ...props }) => (
          <Heading textStyle="h2" mt={4} mb={3} {...props} {...textProps} />
        ),
        h3: ({ node, ...props }) => (
          <Heading textStyle="h3" mt={4} mb={3} {...props} {...textProps} />
        ),
        h4: ({ node, ...props }) => (
          <Subheading textStyle="h4" mt={4} mb={3} {...props} {...textProps} />
        ),
        h5: ({ node, ...props }) => (
          <Subheading textStyle="h5" mt={4} mb={3} {...props} {...textProps} />
        ),
        h6: ({ node, ...props }) => (
          <Subheading textStyle="h6" mt={4} mb={3} {...props} {...textProps} />
        ),
        ul: ({ node, ordered, ...props }) => (
          <UnorderedList textStyle={textStyle} {...props} />
        ),
        li: ({ node, ordered, ...props }) => (
          <ListItem textStyle={textStyle} {...props} />
        ),
        ol: ({ node, ordered, ...props }) => (
          <OrderedList textStyle={textStyle} {...props} />
        ),
        p: ({ node, ...props }) => (
          <Text
            fontWeight="thin"
            whiteSpace="pre-wrap"
            textStyle={textStyle}
            {...props}
            {...textProps}
          />
        ),
        a: ({ node, ...props }) => (
          <Link
            target="_blank"
            color="blue.500"
            fontWeight="bold"
            href=""
            textStyle={textStyle}
            {...linkProps}
            {...props}
          />
        ),
        img: ({ node, ...props }) => <Image alt="" {...props} />,
        ...components,
      }}
      {...props}
    >
      {format_children ? children.replace(/\n/gi, "&nbsp; \n") : children}
    </RMarkdown>
  );
};
