import { Link } from "@chakra-ui/next-js";
import {
  Box,
  Button,
  ButtonProps,
  Center,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { ChevronDown } from "~/images/svgs";
import CircleX from "~/images/svgs/CircleX";
import ListNested from "~/images/svgs/ListNested";
import { useIsDesktop, useNavbarHeight } from "~/lib/hooks";
import { CommunitiesDrawer, MobileDrawer, RoadTripsDrawer } from "../Drawers";
import { Navbar } from "./Navbar";

const navLinkStyleLarge = {
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  gap: 2,
};
export const Header = () => {
  const { asPath } = useRouter();
  const isDesktop = useIsDesktop();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navbarHeight = useNavbarHeight();
  const {
    isOpen: isCommunitiesOpen,
    onClose: onCommunitiesClose,
    onToggle: onCommunitiesToggle,
  } = useDisclosure();
  const {
    isOpen: isRoadTripsOpen,
    onClose: onRoadTripsClose,
    onToggle: onRoadTripsToggle,
  } = useDisclosure();
  const communitiesClick = () => {
    onRoadTripsClose();
    onCommunitiesToggle();
  };
  const roadTripsClick = () => {
    onCommunitiesClose();
    onRoadTripsToggle();
  };
  const handleClose = useCallback(() => {
    onClose();
    onCommunitiesClose();
    onRoadTripsClose();
  }, [onClose, onCommunitiesClose, onRoadTripsClose]);
  useEffect(() => {
    handleClose();
  }, [asPath, handleClose]);
  return (
    <Box
      w="100%"
      position="absolute"
      padding={{ base: "16px" }}
      h={navbarHeight}
      zIndex="popover"
    >
      <Navbar onNav={handleClose}>
        {!isDesktop ? (
          <Center w="100%" h="100%" justifyContent="flex-end">
            {isOpen ? (
              <CircleX onClick={handleClose} />
            ) : (
              <ListNested onClick={onOpen} />
            )}
          </Center>
        ) : (
          <Center
            alignItems="center"
            w="100%"
            h="100%"
            justifyContent="flex-start"
          >
            <Box display="flex" flexDirection="row" gap={8}>
              <MenuNavItem
                // active={isCommunitiesOpen}
                active={true}
                chevron={false}
                // onClick={communitiesClick}
              >
                <Link
                  href="/stories/why-travel-highway-3"
                  onClick={handleClose}
                >
                  Communities
                </Link>
              </MenuNavItem>
              <MenuNavItem active={isRoadTripsOpen} onClick={roadTripsClick}>
                Road Trips
              </MenuNavItem>
              <MenuNavItem active={true} chevron={false}>
                <Link href="/stories" onClick={handleClose}>
                  Stories
                </Link>
              </MenuNavItem>
            </Box>
          </Center>
        )}
      </Navbar>
      <MobileDrawer
        isOpen={isOpen}
        onClose={onClose}
        handleRoadTripClick={roadTripsClick}
        handleCommunitiesClick={communitiesClick}
      >
        <CommunitiesDrawer
          onClose={onCommunitiesClose}
          isOpen={isCommunitiesOpen}
        />
        <RoadTripsDrawer onClose={onRoadTripsClose} isOpen={isRoadTripsOpen} />
      </MobileDrawer>
    </Box>
  );
};

type MenuNavProps = ButtonProps & { active: boolean; chevron?: boolean };
const MenuNavItem = ({
  active,
  chevron = true,
  children,
  ...props
}: MenuNavProps) => {
  return (
    <Button
      colorScheme="transparent"
      style={navLinkStyleLarge}
      {...props}
      p={0}
    >
      <Text
        whiteSpace="nowrap"
        fontSize="l"
        color="gray.900"
        sx={{ _hover: { textDecoration: "underline" } }}
      >
        {children}
      </Text>
      {chevron && (
        <ChevronDown
          width={4}
          height={4}
          color="gray.900"
          mt="5px"
          ml="5px"
          sx={{
            transform: `rotate(${active ? 180 : 0}deg)`,
            transitionProperty: "transform",
            transitionDuration: "0.25s",
          }}
        />
      )}
    </Button>
  );
};
