import { createIcon } from "@chakra-ui/icons";

export const Pin = createIcon({
  displayName: "Pin",
  viewBox: "0 0 12 16",
  path: (
    <>
      <path
        fill="currentColor"
        d="M10.166 8.94C9.642 10.002 8.932 11.06 8.206 12.01C7.5173 12.9056 6.7809 13.7635 6 14.58C5.21908 13.7635 4.48268 12.9056 3.794 12.01C3.068 11.06 2.358 10.002 1.834 8.94C1.304 7.867 1 6.862 1 6C1 4.67392 1.52678 3.40215 2.46447 2.46447C3.40215 1.52678 4.67392 1 6 1C7.32608 1 8.59785 1.52678 9.53553 2.46447C10.4732 3.40215 11 4.67392 11 6C11 6.862 10.695 7.867 10.166 8.94ZM6 16C6 16 12 10.314 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 2.37122e-08 4.4087 0 6C0 10.314 6 16 6 16Z"
      />
      <path
        fill="currentColor"
        d="M6 8C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8ZM6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9Z"
      />
    </>
  ),
});
