import { SimpleGrid } from "@chakra-ui/react";
import { StrapiBlocksPairedImagesComponent } from "../data-contracts";
import { BlocksProps } from "../types";
import { Image } from "./Image";

type Props = BlocksProps["paired_images_props"] & {
  block: StrapiBlocksPairedImagesComponent;
};

export const PairedImagesBlock = ({
  block,
  wrapper_props,
  image_props,
}: Props) => {
  const { image1, image2, caption1, caption2 } = block;
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} gap={10} p={4} {...wrapper_props}>
      <Image image={image1} caption={caption1} {...image_props} />
      <Image
        image={image2}
        caption={caption2}
        mt={{ base: 0, md: 100 }}
        {...image_props}
      />
    </SimpleGrid>
  );
};
