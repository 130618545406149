import {
  AspectRatio,
  Flex,
  FlexProps,
  Heading,
  Image,
  LinkBox,
} from "@chakra-ui/react";
import { Subheading } from "./Text";

import { OptionalLinkOverlay } from "./OptionalLinkOverlay";

type Props = FlexProps & {
  image?: string;
  title?: string;
  name?: string;
  rotate?: "left" | "right";
  href?: string;
};

export const Polaroid = ({
  image,
  title,
  name,
  rotate,
  href = "",
  ...props
}: Props) => {
  const isLeft = rotate === "left";
  const isRight = rotate === "right";
  return (
    <LinkBox
      as={Flex}
      display={"inline-flex"}
      // w="auto"
      w={{ base: 390, md: "500px" }}
      maxW={{ base: "70vw", md: "500px" }}
      bg="white"
      mr={{ base: 4, md: 8, lg: 12 }}
      direction="column"
      align="center"
      transition={"transform"}
      transitionDuration={"0.25s"}
      transform={`rotate(${isLeft ? -1 : isRight ? 1 : 0}deg)`}
      boxShadow="-10px 10px 20px 0px rgba(0, 0, 0, 0.12)"
      borderRadius="sm"
      p={{ base: 6, md: 8 }}
      gap={2}
      {...props}
    >
      <AspectRatio ratio={1} w="100%" mb={8}>
        <Image objectFit={"cover"} src={image} borderRadius="2px" />
      </AspectRatio>

      <Subheading fontSize={{ base: "xl" }}>{title}</Subheading>
      <OptionalLinkOverlay href={href}>
        <Heading fontSize="xl" fontWeight="bold" textAlign="center">
          {name}
        </Heading>
      </OptionalLinkOverlay>
    </LinkBox>
  );
};
