import * as React from "react";
import { SVGProps } from "react";
const Compass = ({ ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#211E1E"}
      d="M29.063 15.967A14.063 14.063 0 1 1 11.32 2.393 1.875 1.875 0 0 1 13.125 0h3.75a1.875 1.875 0 0 1 1.804 2.393 14.068 14.068 0 0 1 10.384 13.574Zm-6.77-7.321-9.28 5.302-5.307 9.283 9.281-5.302 5.303-9.281.004-.002Z"
    />
  </svg>
);
export default Compass;
