import { Box, BoxProps } from "@chakra-ui/react";

export const Logo = (props: BoxProps) => {
  return (
    <Box {...props}>
      <svg viewBox="0 0 1366.5 282.3" height="100%">
        <path
          fill="currentColor"
          d="M791.2,94.7l-64.8-93h-74L730,111.4c18.4,26,28.3,57.1,28.3,88.9v69.9h65.6v-66c0-35,10.3-69.2,29.5-98.4
	L922,1.7h-71.7L791.2,94.7L791.2,94.7z"
        />
        <polygon
          fill="currentColor"
          points="166.4,107 65.2,107 65.2,1.5 0,1.5 0,270 65.2,270 65.2,165.3 166.4,165.3 166.4,270 231.7,270
	231.7,1.5 166.4,1.5 "
        />
        <path
          fill="currentColor"
          d="M529.6,142.2c-1.8,6.4-3.2,12.1-4.2,17.1c-1,5-1.8,9.5-2.3,13.6c-0.2,1.7-0.4,3.4-0.6,5
	c-0.8-3.8-1.8-7.6-2.8-11.5c-2-7.4-4.6-15.3-7.7-23.8L472.9,36.4h-52.2l-39.9,106.2c-2.3,7.2-4.2,14-5.8,20.5
	c-1.2,5.3-2.3,11-3.3,17c-0.9-5.7-2-11.8-3.4-18.1c-1.4-6.5-3-13-4.8-19.4L323.3,1.5h-66.4L337.5,270h51.4l55.3-145l59.7,145h49.9
	L634.3,1.5h-64.8L529.6,142.2L529.6,142.2z"
        />
        <path
          fill="currentColor"
          d="M1299.7,66.1c0.4-0.7,0.7-1.6,0.7-2.6c0-0.9-0.2-1.7-0.7-2.4s-1.1-1.1-2-1.5c-0.8-0.3-1.9-0.5-3-0.5h-9v9.1h9.3
	c1.1,0,2.1-0.2,2.9-0.6C1298.7,67.3,1299.3,66.8,1299.7,66.1L1299.7,66.1z"
        />
        <path
          fill="currentColor"
          d="M1296,50.9c0.8-0.3,1.3-0.8,1.7-1.5s0.6-1.4,0.6-2.3c0-1.3-0.4-2.3-1.3-3.1s-2.2-1.1-4-1.1h-7.2v8.4h7.6
	C1294.3,51.4,1295.2,51.2,1296,50.9L1296,50.9z"
        />
        <path
          fill="currentColor"
          d="M1264.7,106.5l45.4,21c2.3,1,5,1,7.3,0l45.4-21c2.2-0.9,3.6-3.3,3.6-5.9V6.3c0-3.5-2.5-6.3-5.6-6.3h-94.2
	c-3.1,0-5.6,2.8-5.6,6.3v94.4C1261.2,103.3,1262.6,105.6,1264.7,106.5L1264.7,106.5z M1318.9,46.6c1.1-2.7,2.7-5,4.7-7
	c2-2,4.4-3.5,7.1-4.6s5.7-1.6,9-1.6c2,0,4,0.3,6,0.8s3.8,1.3,5.4,2.3c1.7,1,3.1,2.3,4.3,3.7l-6.3,7.2c-0.8-0.9-1.8-1.7-2.7-2.3
	c-0.9-0.7-2-1.2-3.1-1.6c-1.1-0.4-2.3-0.6-3.7-0.6c-1.6,0-3.2,0.3-4.6,0.9c-1.4,0.6-2.7,1.4-3.8,2.5c-1.1,1.1-1.9,2.4-2.5,3.9
	c-0.6,1.5-0.9,3.3-0.9,5.2s0.3,3.6,0.9,5.1s1.5,2.8,2.6,3.9c1.1,1.1,2.4,1.9,4,2.5c1.6,0.6,3.3,0.9,5.2,0.9c1.3,0,2.5-0.2,3.7-0.6
	c1.2-0.4,2.3-0.9,3.2-1.5s1.9-1.4,2.7-2.2l5,7.9c-1,1.1-2.4,2.1-4,3s-3.6,1.6-5.6,2.1c-2.1,0.5-4.1,0.8-6.2,0.8
	c-3.2,0-6.2-0.5-8.8-1.6s-5-2.6-7-4.6s-3.5-4.3-4.6-7c-1.1-2.7-1.6-5.7-1.6-8.9S1317.8,49.3,1318.9,46.6L1318.9,46.6z M1275.9,34.3
	h18.8c2.9,0,5.4,0.4,7.4,1.2s3.6,2,4.6,3.6c1,1.6,1.6,3.4,1.6,5.6c0,2.4-0.6,4.5-1.8,6.1c-0.9,1.2-2,2.1-3.4,2.7
	c1.1,0.4,2.2,0.9,3.1,1.6c1.4,1,2.5,2.3,3.2,3.7c0.8,1.5,1.2,3.1,1.2,5c0,2.1-0.4,4-1.1,5.6c-0.7,1.6-1.8,2.9-3.2,4
	c-1.4,1.1-3,1.9-4.8,2.4c-1.8,0.5-3.9,0.8-6.1,0.8H1276L1275.9,34.3L1275.9,34.3z"
        />
        <path
          fill="currentColor"
          d="M1063.2,68.4c11.2,8.1,26.8,5,34.3-6.5c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.3-0.2-0.7-0.3-0.8-0.4
	c-5.4-2.5-11.8-3.8-18.3-3.8c-8.1,0-16.1,2-23,5.7L1063.2,68.4L1063.2,68.4z"
        />
        <path
          fill="currentColor"
          d="M1082,45.8l14.7,6.8l8.6-6.8l-4.4-3.7c0.1,0.5,0.2,0.9,0.2,1.4c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7
	c0-1.9,0.8-3.7,2.2-4.9l-6,2.5L1082,45.8L1082,45.8z"
        />
        <path
          fill="currentColor"
          d="M1104.5,43.2l-3.6-1.2l0,0l0,0l0,0l0,0c0.1,0.5,0.2,0.9,0.2,1.4c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7
	c0-1.9,0.8-3.7,2.2-4.9l0,0c-0.3-0.1-0.6-0.1-0.9-0.1s-0.6-0.1-1-0.1c0,0,0,0,0,0c-0.5,0-0.9-0.1-1.4-0.1c-1.5,0-3,0.2-4.5,0.6
	c-2.7,0.7-5.3,2.1-7.4,3.9l-7.6,6.7c-1.8,1.6-3.7,3.1-5.7,4.6c5.5-1.8,8.2-3.5,17-2.5c10.4,1.1,19.7,6.6,20.7,7.1
	c5-5.8,11.5-9.5,17.1-11.5L1104.5,43.2L1104.5,43.2z"
        />
        <path
          fill="currentColor"
          d="M1200,125.6c-8.4-7.8-17.9-14-28.6-18.4c-7.2-3-14.7-4.9-22.6-5.9l70-75.2l-12.2-24.3h-168.5V54l0.3-0.2
	c7.5-3.4,14.4-7.9,20.6-13.3l7.7-6.8c3.6-3.1,7.8-5.4,12.4-6.6c5.6-1.4,11.5-1.2,16.9,0.6l34.3,11.3l12.9,2.9
	c5.5,1.2,10.5,4.3,13.8,8.9c0.1,0.1,0.1,0.1,0.2,0.2c1.8,2.5,2.7,5.5,2.7,8.6v1.7l-1-1.3c-1.5-2-3.9-3.1-6.4-3.1h-24.8
	c-8.2,0-15.6,4.4-19.9,11.4c-1.1,1.8-2.8,3.4-3.4,5.4c-9.3,30.6-25.6,48.3-34,55.9l12,32c5.2-2.6,10.3-4.7,15.3-6.3
	c5-1.6,9.7-2.8,14.1-3.7s8.6-1.4,12.5-1.4c8.6,0,16,1.4,22.1,4.3c6.1,2.9,10.8,7.1,14.1,12.7c3.3,5.6,4.9,12.5,4.9,20.6
	c0,7.6-1.9,14.4-5.7,20.4c-3.8,6-8.8,10.6-14.9,13.9c-6.1,3.3-12.9,4.9-20.2,4.9s-14.3-1.2-21-3.7c-6.7-2.5-13.1-6.5-19.2-12
	c-6.1-5.5-12.1-12.8-17.8-22l-47.8,31c13.6,22.7,28.7,38.7,45.3,48c16.6,9.3,36,13.9,58.2,13.9c19.3,0,36.9-4.2,52.7-12.5
	c15.8-8.4,28.3-19.7,37.6-33.9c9.3-14.2,13.9-30.5,13.9-48.8c0-12-2.3-23.3-7.1-33.9C1214.9,142.6,1208.4,133.4,1200,125.6
	L1200,125.6z"
        />
      </svg>
    </Box>
  );
};
